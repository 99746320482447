import React, { useState } from "react";
import NextImage from "next/image";

import { Box } from "@/components/Atoms/Box";

import theme from "@/foundation/Theme";
import Typography from "@/foundation/Typography/V2";

import {
  Wrapper,
  Container,
  CardsWrapper,
  CardBox,
  LHSBox,
  ImageBox,
  SubText,
  SectionImageBox,
  BoxWrap,
} from "./styles";

export const ServiceWrapper = ({ data = {}, width, setBackground = false }) => {
  const [currentHoverEl, setCurrentHoverEl] = useState(0);
  return (
    <CardsWrapper setBackground={setBackground}>
      {data?.services?.components?.map((item, i) => {
        return (
          <CardBox
            isHoverActive={currentHoverEl === i}
            key={i}
            onMouseEnter={() => setCurrentHoverEl(i)}
          >
            <LHSBox>
              {item.logo && (
                <ImageBox>
                  <NextImage
                    src={item.logo}
                    alt={item?.logo_alt_text}
                    fill
                    style={{ objectFit: "cover" }}
                  />
                </ImageBox>
              )}
              <BoxWrap>
                <Typography
                  color={theme.colorsV2.primary.shade_700}
                  variant={item.url ? "link2" : "heading5"}
                  fontSize={["18px", "21px", "24px"]}
                  lineHeight={["24px", "28px", "32px"]}
                  target="_blank"
                  href={item.url?.endsWith("/") ? item.url : `${item.url}/`}
                  mb={2}
                >
                  {item.title}
                </Typography>
                <SubText
                  fontFamily={"var(--man-700)"}
                  variant={"paragraph3"}
                  flex={"1"}
                  letterSpacing="0"
                  lineHeight={["20px", "20px", "20px"]}
                >
                  {item.sub_title}
                </SubText>
              </BoxWrap>
            </LHSBox>
            {width > 1079 && item.image && (
              <SectionImageBox ml={currentHoverEl === i ? "32px" : "0px"}>
                <Box
                  style={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {item.url ? (
                    <Typography
                      variant="link2"
                      fontSize={["18px", "21px", "24px"]}
                      lineHeight={["24px", "28px", "32px"]}
                      target="_blank"
                      href={item.url?.endsWith("/") ? item.url : `${item.url}/`}
                    >
                      <NextImage
                        src={item.image}
                        alt={item?.image_alt_text}
                        fill
                      />
                    </Typography>
                  ) : (
                    <NextImage
                      src={item.image}
                      alt={item?.image_alt_text}
                      fill
                    />
                  )}
                </Box>
              </SectionImageBox>
            )}
          </CardBox>
        );
      })}
    </CardsWrapper>
  );
};

function Services(props) {
  const { data = {}, width } = props || {};
  return (
    <Wrapper>
      <Container flexDirection={"column"}>
        <ServiceWrapper data={data} width={width} />
      </Container>
    </Wrapper>
  );
}

export default Services;
