import styled from "styled-components";

import theme from "../../../../foundation/Theme";
import Typography from "../../../../foundation/Typography/V2";

export const Tags = styled(Typography)`
  margin-right: 12px;
  user-select: none;
  transition: all 0.2s ease-in-out;
  padding: 7px 11px;
  border: 1px solid
    ${({ isSelected }) =>
      isSelected
        ? theme.colorsV2.shyft.purple_text
        : theme.colorsV2.gray.shade_300};
  border-radius: 8px;
  cursor: pointer;
  &:last-child {
    margin-right: 0px;
  }
  @media only screen and (max-width: 767px) {
    margin-right: 8px;
    &:last-child {
      margin-right: 0px;
    }
  }
`;
