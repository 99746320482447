import styled from "styled-components";

import { FlexDiv } from "@/components/Atoms/Box";
import CheckBox from "@/components/Atoms/Inputs/CheckBox";

export const BodyWrap = styled(FlexDiv)``;

export const FormBox = styled.form`
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const VerifyCheckBox = styled(CheckBox)``;
