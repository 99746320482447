const data = {
  tagline:
    "Lifestyle management and tracking for specific health conditions like chronic ailments, pregnancy care, chronic pains and more.",
  categories: {
    company: [
      // {
      //   link: "/about-us", //TODO: Add Link
      //   text: "About Us",
      // },
      {
        link: "/careers",
        text: "Careers",
        type: "link1",
      },
      // {
      //   link: "/shyft-team",
      //   text: "Team",
      // },
      // {
      //   link: "/partners", //TODO: Add Link
      //   text: "Partners",
      // },
      {
        link: "/contact-us",
        text: "Contact Us",
        type: "link1",
      },
      {
        link: "/corporate-partnerships",
        text: "Corporate ",
        type: "link1",
      },
      {
        link: "/offers",
        text: "Offers ",
        type: "link1",
      },
    ],
    social: [
      {
        link: "https://in.linkedin.com/company/shyftwellness", //TODO: Confirm Link
        icon: "icon-footer-linkedin",
        iconDark: "icon-footer-linkedin-light",
        text: "LinkedIn",
        external: true,
      },
      {
        link: "https://twitter.com/shyfthealth",
        icon: "icon-footer-twitter",
        iconDark: "icon-footer-twitter-light",
        text: "Twitter",
        external: true,
      },
      {
        link: "https://www.instagram.com/shyfthealth/",
        icon: "icon-footer-instagram",
        iconDark: "icon-footer-instagram-light",
        text: "Instagram",
        external: true,
      },
      {
        link: "https://www.facebook.com/shyftwellness/",
        icon: "icon-footer-facebook",
        iconDark: "icon-footer-facebook-light",
        text: "Facebook",
        external: true,
      },
    ],
    resources: [
      {
        link: "https://www.betheshyft.com/healthwatch/?utm_source=shyft_website&utm_medium=shyft_website_link&adset_name=website_link",
        text: "Blog",
        external: true,
      },
      {
        link: "/webinars",
        text: "Webinars ",
        type: "link1",
      },
      {
        link: "/recipes/",
        text: "Recipes ",
        type: "link1",
      },
      {
        link: "/community/thread/",
        text: "Community ",
        type: "link1",
      },

      // {
      //   link: "/guides", //TODO: Add Link
      //   text: "Guides",
      // },
      // {
      //   link: "/press", //TODO: Add Link
      //   text: "Press",
      // },
    ],
  },
  appCard: {
    title: "Try Shyft for free",
    subtitle: "Download Shyft Health App and start your wellness journey",
    appLinks: [
      {
        link: "https://play.google.com/store/apps/details?id=com.mindhouse",
        icon: "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/website/footer/Google-dark.svg",
        iconDark:
          "https://d1mxd7n691o8sz.cloudfront.net/static/nutrition/nutritiongoal/2023-06/Google_Light-0f53e36e83aa43beaa054bf3c26a69ce.svg",
        text: "play-store",
        external: true,
      },
      {
        link: "https://apps.apple.com/in/app/shyft-health/id1484471377",
        icon: "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/website/footer/Apple-dark.svg",
        iconDark:
          "https://d1mxd7n691o8sz.cloudfront.net/static/nutrition/nutritiongoal/2023-06/Apple_Light-6ef14b8a4a4d4f70bfe2ee8b9d016ecd.svg",
        text: "apple-store",
        external: true,
      },
    ],
    image:
      "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/website/footer/footer_illustration.svg",
  },
  terms: [
    {
      text: "Ⓒ 2022 Blacktail Mindhouse Inc.",
    },
    // {
    //   link: "/terms-condition", //TODO: Change Link
    //   text: "Support",
    // },
    {
      link: "/privacy-policy",
      text: "Privacy Policy",
    },
    {
      link: "/terms-condition",
      text: "Terms of Use",
    },
    {
      link: "/cookie-policy",
      text: "Cookie Policy",
    },
    {
      link: "/medical-waiver-disclaimer",
      text: "Medical Waver & Disclaimer",
    },
  ],
};

export default data;
