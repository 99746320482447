import styled from "styled-components";

import { FlexDiv } from "@/components/Atoms/Box";
import SVGSprite from "@/components/Atoms/SVGSprite";

export const DropDown = styled(FlexDiv)`
  opacity: ${({ showCountryDropDown }) => (showCountryDropDown ? "1" : "0")};
  visibility: ${({ showCountryDropDown }) =>
    showCountryDropDown ? "visible" : "hidden"};
`;

export const InputWrap = styled(FlexDiv)`
  position: relative;
  width: 100%;
  .shyft__placeholder {
    right: 0;
    color: ${({ theme }) => theme.colors.placeholderGrey};
  }
  .shyft__value-container {
    position: relative;
    padding: 0px !important;
    .shyft__input input {
      max-width: 34px;
    }
    .icon-phone-2 {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &.shyft__value-container--has-value {
      .icon {
        display: none;
      }
    }
  }
  ${DropDown} {
    .shyftList {
      min-width: 100px;
      position: absolute;
      top: 50%;
      width: 100%;
      max-width: 100px;
      transform: translateY(-50%);
      z-index: 3;
      left: 0px;
      border: 1px solid ${({ theme }) => theme.colorsV2.gray.shade_300};
      box-shadow: 2px 4px 4px 0
        rgba(${({ theme }) => theme.colors.shadows.banner}, 0.1);
      background-color: ${({ theme }) => theme.colors.white};
      padding-bottom: 8px;
      border-radius: 8px;
      overflow: hidden;
      > div {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .shyft__menu {
        position: relative;
        transform: none;
        order: 0;
        border: 0px;
        box-shadow: none;
        border-radius: 0px;
        margin-bottom: 12px;
        .shyft__option {
          padding: 4px 8px;
          &.shyft__option--is-selected.shyft__option--is-focused,
          &.shyft__option--is-selected {
            p {
              color: ${({ theme }) => theme.colors.white}!important;
            }
          }
        }
      }
      .shyft__control {
        order: 1;
        width: 100%;
        max-width: 84px;
        margin: 0 auto;
        min-height: 32px;
        max-height: 32px;
        .shyft__indicators {
          display: none;
        }
        &.shyft__control--is-focused {
          border-color: ${({ theme }) => theme.colorsV2.gray.shade_700};
        }
        .shyft__value-container {
          padding: 4px 6px !important;
          height: 100%;
          justify-content: flex-start;
          > div:not(.shyft__placeholder),
          .shyft__placeholder + div {
            margin: 0 2px !important;
            padding: 0 !important;
            height: 100%;
            .shyft__input {
              height: 100%;
              font-size: 12px !important;
              display: flex !important;
              align-items: center;
              color: ${({ theme }) => theme.colorsV2.gray.shade_700} !important;
              input {
                height: 100%;
                width: 100%;
                max-width: 56px !important;
                margin: 0 auto !important;
              }
            }
          }
          .shyft__single-value {
            left: 4px;
            height: 100%;
            padding: 0 4px;
            &:before {
              content: "Search";
              font-size: 12px;
            }
            > div {
              opacity: 0;
            }
          }
        }
        .shyft__placeholder {
          color: ${({ theme }) => theme.colorsV2.gray.shade_400};
          font-size: 12px;
          font-family: var(--man-700) !important;
          right: unset;
        }
      }
    }
  }
`;

export const CountryPlaceholder = styled(FlexDiv)`
  position: absolute;
  height: calc(100% - 14px);
  top: 50%;
  transform: translateY(-50%);
  z-index: ${({ showCountryDropDown }) => (showCountryDropDown ? "3" : "2")};
  left: ${({ variant }) => (variant === "v3" ? "12px" : "0px")};
  width: 88px;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  border-radius: 0px;
  border-right: 1px solid ${({ theme }) => theme.colorsV2.gray.shade_200};
  max-height: 20px;
`;

export const PhoneIcon = styled(SVGSprite)``;

export const PhoneFlag = styled(FlexDiv)``;

export const ArrowDown = styled(SVGSprite)``;

export const NumberPlaceholder = styled(FlexDiv)`
  width: 100%;
  padding: 0px 24px 0px 23px;
  align-items: center;
  cursor: pointer;
  ${PhoneIcon} {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
  ${PhoneFlag} {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
  ${ArrowDown} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 7px;
  }
`;
