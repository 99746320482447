import React, { useCallback, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";

const FullScreenLoader = dynamic(
  () => import("@/components/Molecules/FullScreenLoader")
);

import Headroom from "@/components/Molecules/Headroom";
import Footer from "@/components/Molecules/Footer";
import Header from "@/components/Organisms/Navbar";

import NotFound from "@/containers/NotFoundPage";
import ErrorBoundary from "@/containers/ErrorPage";

import theme from "@/foundation/Theme";

import { useAppSelector, useAppDispatch, useWindowDimensions } from "@/hooks";
import * as dashboardActions from "@/redux/DashboardReducer";
interface MainTemplateProps {
  children: React.ReactNode;
  // footerRef: propTypeRef,
  isLoading?: boolean;
  success?: boolean;
  hideHeader?: boolean;
  isStickyHeader?: boolean;
  hideFooter?: boolean;
  hideLinks?: boolean;
  isDarkMode?: boolean;
  error?: string;
  isFixedLoader?: boolean;
  headerBackgroundColor?: string;
  showTransparentHeader?: boolean;
  darkHeader?: boolean;
  hideRibbon?: boolean;
  appDownloadLink?: string;
  isLandingPage?: boolean;
  footerRef?: React.ReactNode;
}

const MainTemplate: React.FC<MainTemplateProps> = ({
  isLoading,
  success = true,
  children,
  hideHeader,
  hideFooter,
  isStickyHeader = true,
  hideLinks,
  isDarkMode = true,
  error,
  footerRef,
  isFixedLoader,
  showTransparentHeader,
  darkHeader,
  headerBackgroundColor,
  hideRibbon,
  appDownloadLink,
  isLandingPage,
}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { width } = useWindowDimensions();
  const headerRef = useRef<HTMLElement>(null);

  const { error: APIError } = useAppSelector((state) => state.auth);
  const { ribbonDataSuccess, ribbonData, routeChangeLoading } = useAppSelector(
    (state) => state.dashboard
  );

  const handleResize = useCallback(() => {
    if (ribbonDataSuccess) {
      const value = headerRef.current?.offsetHeight;
      // TODO:add debouncing
      dispatch(dashboardActions.setHeaderHeight(value));
    }
  }, [dispatch, ribbonDataSuccess]);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      window.addEventListener("orientationchange", handleResize);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("orientationchange", handleResize);
      }
    };
  }, [handleResize]);

  if ((isLoading || routeChangeLoading) && !isFixedLoader) {
    return <FullScreenLoader />;
  }
  if (typeof APIError === "object" && Object.keys(APIError)?.length > 0) {
    if (APIError?.response?.status >= 500) {
      //NOTE: throwing this error to show error page
      throw new Error(APIError?.message);
    }
  }
  if (
    error && typeof error === "object" ? Object.keys(error)?.length > 0 : error
  ) {
    return <NotFound />;
  }

  return (
    <ErrorBoundary
      darkHeader={darkHeader}
      hideLinks={hideLinks}
      windowWidth={width}
      headerBackgroundColor={headerBackgroundColor}
      hideRibbon={true}
      hideHeader={hideHeader}
      history={router}
    >
      {(isLoading || routeChangeLoading) && (
        <FullScreenLoader isFixedLoader={isFixedLoader} />
      )}
      {!hideHeader && (
        <Header
          showTransparentHeader={showTransparentHeader}
          darkHeader={darkHeader}
          hideLinks={hideLinks}
          windowWidth={width}
          headerBackgroundColor={headerBackgroundColor}
          isStickyHeader={isStickyHeader}
          hideRibbon={hideRibbon}
          appDownloadLink={appDownloadLink}
          headerRef={headerRef}
          isLandingPage={isLandingPage}
        />
      )}
      {children}
      {!hideFooter && <Footer footerRef={footerRef} dark={isDarkMode} />}
    </ErrorBoundary>
  );
};

export default MainTemplate;
