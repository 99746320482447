import theme from "../../foundation/Theme";

const heroServiceComponentImagesData = [
  {
    logo: "health-logo",
    image: "Nutrition Plan - Shyft",
  },
  {
    logo: "nutrition-logo",
    image: "Yoga Plans - Shyft",
  },
  {
    logo: "track-logo",
    image: "Tracking & Progress - Shyft",
  },
];

const SliderServiceComponentImagesData = [
  {
    logo: "staying-fit-logo",
    image: "Staying Fit - Shyft",
  },
  {
    logo: "weight-management-logo",
    image: "Weight Management - Shyft",
  },
  {
    logo: "diabetes-logo",
    image: "Diabetes - Shyft",
  },
  {
    logo: "thyroid-logo",
    image: "Thyroid - Shyft",
  },
  {
    logo: "hypertension-logo",
    image: "Hypertension - Shyft",
  },
  {
    logo: "pcos-logo",
    image: "PCOS - Shyft",
  },
  {
    logo: "prenatal-logo",
    image: "Prenatal - Shyft",
  },
  {
    logo: "postnatal-logo",
    image: "Postnatal - Shyft",
  },
];

export const mapHeroSectionData = (response) => {
  const BE_DATA = response;
  const data = {};

  data.title = BE_DATA?.hero?.hero_title;
  data.description = BE_DATA?.hero?.hero_description;
  data.subtitle = BE_DATA?.hero?.hero_subtitle;
  data.primaryCTA = BE_DATA?.hero?.hero_cta_title;
  data.secondaryCTA = BE_DATA?.hero?.hero_cta_title_secondary;
  data.features = BE_DATA?.highlights?.components;
  data.services = BE_DATA?.services;

  const formattedServiceData = data?.services?.components?.map(
    (item, index) => {
      return {
        ...item,
        logo_alt_text: heroServiceComponentImagesData[index].logo,
        image_alt_text: heroServiceComponentImagesData[index].image,
      };
    }
  );

  data.services = {
    ...data?.services,
    components: formattedServiceData,
  };
  return data;
};

export const mapCustomerStories = (response) => {
  const BE_DATA = response;
  const data = {};

  data.badge = BE_DATA?.testimonials?.title;
  data.title = BE_DATA?.testimonials?.sub_title;
  data.storyData = BE_DATA?.testimonials?.components;

  return data;
};
export const mapWellnessConditionData = (response) => {
  const BE_DATA = response;
  const data = {};

  data.title = BE_DATA?.medical_conditions?.title;
  data.components = BE_DATA?.medical_conditions?.components;

  const formattedServiceData = data?.components?.map((item, index) => {
    return {
      ...item,
      logo_alt_text: SliderServiceComponentImagesData[index].logo,
      image_alt_text: SliderServiceComponentImagesData[index].image,
    };
  });

  data.components = formattedServiceData;

  return data;
};
export const mapRegisterModalData = (formData, pathname = null) => {
  const modalData = formData?.forms;

  const data = {};

  data.title = modalData?.title || "Ready to make the Shyft ?";
  data.subTitle =
    modalData?.sub_title ||
    "Fill the form and our Team will get back to you within 24 hrs.";
  data.buttonText = modalData?.buttonCTA || "Close";
  data.defaultPackageSlug =
    pathname === "/"
      ? "health-for-others-ind-49"
      : modalData?.services?.slugs?.[0]?.slug;
  data.successImage = modalData?.image;
  data.submissionTitle = modalData?.submission_title;
  data.submissionSubtitle = modalData?.submission_subtitle;
  data.submissionExtraText = modalData?.submission_extra_text;
  data.callUs = modalData?.bottom_title;
  data.contact = modalData?.number;
  data.writeToUs = modalData?.bottom_sub_title;
  data.emailUs = modalData?.email;
  data.formImage = modalData?.before_submission_image;
  data.formDropdown = modalData?.services?.slugs;

  return data;
};

export const mapHowItWorksSection = (response) => {
  const BE_DATA = response;

  const data = {};

  data.sectionHeading = BE_DATA?.features_and_benefits?.title;

  data.BANNER_DATA = [
    {
      heading: BE_DATA?.features_and_benefits?.components[0]?.title,
      subheading: BE_DATA?.features_and_benefits?.components[0]?.sub_title,
      color: "#97D631",
      icon: "icon-diet-plan-homepage",
      image: BE_DATA?.features_and_benefits?.components[0]?.image,
      altText: "Personalized Diet Plan - Shyft",
    },
    {
      heading: BE_DATA?.features_and_benefits?.components[1]?.title,
      subheading: BE_DATA?.features_and_benefits?.components[1]?.sub_title,
      color: theme.colorsV2.success.shade_500,
      icon: "icon-noun-community-homepage",
      image: BE_DATA?.features_and_benefits?.components[1]?.image,
      altText: "Join our Community - Shyft",
    },

    {
      heading: BE_DATA?.features_and_benefits?.components[2]?.title,
      subheading: BE_DATA?.features_and_benefits?.components[2]?.sub_title,
      color: theme.colorsV2.shyft.purple_text,
      icon: "icon-chat-shyft",
      image: BE_DATA?.features_and_benefits?.components[2]?.image,
      altText: "Dedicated Health Coach - Shyft",
    },
    {
      heading: BE_DATA?.features_and_benefits?.components[3]?.title,
      subheading: BE_DATA?.features_and_benefits?.components[3]?.sub_title,
      color: theme.colorsV2.blue.shade_600,
      icon: "icon-camera",
      image: BE_DATA?.features_and_benefits?.components[3]?.image,
      altText: "Live Interactive Yoga Sessions - Shyft",
    },
    {
      heading: BE_DATA?.features_and_benefits?.components[4]?.title,
      subheading: BE_DATA?.features_and_benefits?.components[4]?.sub_title,
      color: theme.colorsV2.blueLight.shade_500,
      icon: "icon-bar",
      image: BE_DATA?.features_and_benefits?.components[4]?.image,
      altText: "Track Your Progress - Shyft",
    },
  ];
  return data;
};
