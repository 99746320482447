import React from "react";

import { propTypeChildren, propTypeRef } from "../../../utils";

import {
  WrapperContainer,
  Wrapper,
  CardBox,
  LeftSection,
  RightSection,
} from "./style";

export function TwoColumnModal({ className, leftSection, rightSection }) {
  return (
    <CardBox className={`${className} card-box`}>
      {leftSection && (
        <LeftSection className="left-section">{leftSection}</LeftSection>
      )}
      {rightSection && (
        <RightSection className="right-section">{rightSection}</RightSection>
      )}
    </CardBox>
  );
}

function TwoColumnSection({ className, reference, leftSection, rightSection }) {
  return (
    <Wrapper className={className}>
      <WrapperContainer className="container" ref={reference}>
        <CardBox className="card-box">
          {leftSection && (
            <LeftSection className="left-section">{leftSection}</LeftSection>
          )}
          {rightSection && (
            <RightSection className="right-section">
              {rightSection}
            </RightSection>
          )}
        </CardBox>
      </WrapperContainer>
    </Wrapper>
  );
}

TwoColumnSection.propTypes = {
  reference: propTypeRef?.isRequired,
  LeftSection: propTypeChildren,
  RightSection: propTypeChildren,
};

TwoColumnSection.defaultProps = {
  LeftSection: null,
  RightSection: null,
};

export default TwoColumnSection;
