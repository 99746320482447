import React from "react";
import styled from "styled-components";

import { FlexDiv } from "../../../components/Atoms/Box";
import SvgRing from ".";
import { TextWrap } from "./TextSlider";


function HowItWorksMobile(props) {
  const { width, data = {} } = props || {};
  const { BANNER_DATA } = data;
  return (
    <>
      {BANNER_DATA?.map((data, index) => {
        return (
          <TempBox
            key={index}
            width={"100%"}
            flexDirection={"column"}
            mb={"100px"}
          >
            <FlexDiv
              position="relative"
              overflow={"hidden"}
              width={"100%"}
              justifyContent="center"
              mb={"34px"}
              maxHeight={"500px"}
            >
              <SvgRing
                width={width}
                active={index + 1}
                imageMobile={data?.image}
              />
            </FlexDiv>
            <FlexDiv px={"15px"}>
              <TextWrap data={data} />
            </FlexDiv>
          </TempBox>
        );
      })}
    </>
  );
}

export default HowItWorksMobile;
const TempBox = styled(FlexDiv)`
  &:last-child {
    margin-bottom: 50px;
  }
`;
