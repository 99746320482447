import styled from "styled-components";

import { FlexDiv } from "@/components/Atoms/Box";

import Typography from "@/foundation/Typography/V2";

export const HeadingWrapper = styled(FlexDiv)`
  @media only screen and (max-width: 1079px) {
    padding: 0 ${({ mobilePadding }) => mobilePadding};
    width: 100%;
    ${({ isTestimonial }) => isTestimonial && `max-width:calc(100% - 90px);`}
  }
`;

export const Title = styled(Typography)`
  margin-bottom: 8px;
  ${({ disableSeparator, themeColor, theme }) =>
    !disableSeparator
      ? `
        padding-left: 28px;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 8px;
          background-color: ${themeColor || theme.colorsV2.shyft.purple_text};
          border-radius: 8px;
          height: 100%;
          width: 4px;
          @media only screen and (max-width: 1079px) {
            left: 16px;
          }
        }
      `
      : `
        padding-left:8px;
        `}
`;
