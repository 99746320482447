import React from "react";
import PropTypes from "prop-types";

import {
  StyledSuccessModal,
  ImageHolder,
  ImageContainer,
  TextContainer,
  Title,
  Subtitle,
  ContactUsSection,
  ContactBlock,
  ContactHeading,
  ContactLink,
  ContactValue,
  ImageValue,
} from "./style";

const SuccessModalV2 = ({
  successImage,
  submissionTitle,
  submissionSubtitle,
  subtitleVariant,
  className,
  headingVariant,
  contact,
  emailUs,
  writeToUs,
  callUs,
  mb = "24px",
  contentAlign,
  imageHeight,
  imageWidth,
}) => {
  return (
    <StyledSuccessModal
      id="requestCallbackSuccessModal"
      className={`callback-message-wrapper ${className}`}
      aria-labelledby="requestCallbackSuccessModal"
      contentAlign={contentAlign}
    >
      {successImage && (
        <ImageContainer>
          <ImageHolder height={imageHeight} width={imageWidth}>
            <ImageValue
              src={successImage}
              width="100%"
              height="100%"
              maxHeight="100%"
            />
          </ImageHolder>
        </ImageContainer>
      )}
      <TextContainer>
        <Title
          variant={headingVariant}
          mb={mb}
          dangerouslySetInnerHTML={{ __html: submissionTitle }}
        />
        <Subtitle
          variant={subtitleVariant}
          dangerouslySetInnerHTML={{ __html: submissionSubtitle }}
        />
        {(contact || emailUs) && (
          <ContactUsSection>
            {contact && (
              <ContactBlock>
                <ContactHeading variant="paragraph2">{callUs}</ContactHeading>
                <ContactLink href={`tel:${contact}`} variant="link1">
                  <ContactValue variant="label1">{contact}</ContactValue>
                </ContactLink>
              </ContactBlock>
            )}
            {emailUs && (
              <ContactBlock>
                <ContactHeading variant="paragraph2">
                  {writeToUs}
                </ContactHeading>
                <ContactLink href={`mailto:${emailUs}`} variant="link1">
                  <ContactValue variant="label1">{emailUs}</ContactValue>
                </ContactLink>
              </ContactBlock>
            )}
          </ContactUsSection>
        )}
      </TextContainer>
    </StyledSuccessModal>
  );
};

SuccessModalV2.propTypes = {
  successImage: PropTypes.string,
  submissionTitle: PropTypes.string,
  submissionSubtitle: PropTypes.string,
  mb: PropTypes.string,
  subtitleVariant: PropTypes.string,
  className: PropTypes.string,
  callUs: PropTypes.string,
  contact: PropTypes.string,
  emailUs: PropTypes.string,
  writeToUs: PropTypes.string,
  contentAlign: PropTypes.string,
};

SuccessModalV2.defaultProps = {
  successImage:
    "https://d1mxd7n691o8sz.cloudfront.net/staging/static/uploads/ok-submit.svg",
  submissionTitle: "Form submitted</br>Thank you for your interest.",
  submissionSubtitle:
    "Our team will get in touch within 24hrs. We look forward to welcoming you to our family &#128522;",
  subtitleVariant: "paragraph1",
  className: "label1",
  headingVariant: "heading1",
  contact: "",
  emailUs: "",
  writeToUs: "Write to us",
  callUs: "Call Us",
  contentAlign: "center",
  imageHeight: "104px",
  imageWidth: "114px",
};

export default SuccessModalV2;
