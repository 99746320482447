import styled from "styled-components";

import { FlexDiv } from "@/components/Atoms/Box";

import Typography from "@/foundation/Typography/V2";

export const Icon = styled(FlexDiv)`
  background-color: ${({ bgCol }) => bgCol};
  border-radius: 50%;
  height: 60px;
  width: 60px;
  align-items: center;
  justify-content: center;
  margin-bottom: 19px;
  @media only screen and (max-width: 1129px) {
    position: absolute;
    left: 0;
    height: 40px;
    width: 40px;
    top: 0;
    .icon {
      transform: scale(0.7);
    }
  }
`;

export const FlyWrapper = styled(FlexDiv)``;

export const FlyBox = styled(FlexDiv)`
  max-width: 468px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  @media only screen and (max-width: 1079px) {
    max-width: 430px;
    margin: 0 auto;
    opacity: 1;
    transition: none;
  }
  @media only screen and (max-width: 1129px) {
    padding-left: 55px;
  }
`;

export const Heading = styled(Typography)``;
export const SubHeading = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.gray.shade_700};
`;

export const TextBox = styled(FlexDiv)`
  &:last-child {
    justify-content: flex-end;
    ${FlyBox} {
      margin-bottom: 50px;
    }
  }
`;
