import styled from "styled-components";

import { FlexSection, FlexDiv, FlexContainer } from "@/components/Atoms/Box";
import Image from "@/components/Atoms/Image";
import { getCustomScroll } from "@/components/Atoms/Scrollbar";

import Typography from "@/foundation/Typography/V2";

export const Wrapper = styled(FlexSection)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 12px 0 48px 0;
  @media only screen and (max-width: 1079px) {
    padding: 32px 0;
  }
`;
export const CardBox = styled(FlexDiv)`
  width: 100%;
  align-items: center;
  max-width: calc(
    ${({ isHoverActive }) => (isHoverActive ? "50%" : "25%")} - 16px
  );
  padding: 16px;
  transition: all 0.5s ease-in-out;
  background-color: ${({ theme }) => theme.colorsV2.blueGray.shade_50};
  border-radius: 32px;
  margin: 8px;
  justify-content: space-between;
  @media only screen and (max-width: 1079px) {
    max-width: 100%;
    background-color: transparent;
    border-radius: 0;
    margin: 0 0 32px 0;
    padding: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
export const CardsWrapper = styled(FlexDiv)`
  flex-wrap: wrap;
  margin: 0 -8px;
  ${({ setBackground, theme }) =>
    setBackground &&
    `
  background-color: ${theme.colors.white};
  border-radius:24px;
  box-shadow:0px 0px 4px rgba(${theme.colors.shadows.black}, 0.04), 0px 4px 8px rgba(${theme.colors.shadows.black}, 0.06);
  ${CardBox}{
    background-color: transparent;
  }
  `};
  @media only screen and (max-width: 1079px) {
    margin: 0;
    flex-direction: column;
  }
`;
export const ImageBox = styled(FlexDiv)`
  position: relative;
  height: 80px;
  width: 80px;
  min-width: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 16px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media only screen and (max-width: 1079px) {
    margin-bottom: 0px;
  }
`;
export const LHSBox = styled(FlexDiv)`
  flex-direction: column;
  max-width: 252px;
  height: 100%;
  width: 100%;
  padding: 8px 16px;
  @media only screen and (max-width: 1079px) {
    flex-direction: row;
    padding: 0;
    max-width: 100%;
  }
`;
export const SectionImageBox = styled(FlexDiv)`
  flex: 1;
  margin-left: 32px;
  border-radius: 24px;
  max-width: 328px;
  width: 100%;
  height: 100%;
  max-height: 300px;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media only screen and (max-width: 1079px) {
    display: none;
  }
`;
export const SubText = styled(Typography)`
  transition: all 0.3s ease-in-out;
  color: ${({ theme }) => theme.colors.gray_90};
  ${getCustomScroll({})}
`;
export const BoxWrap = styled(FlexDiv)`
  flex-direction: column;
  flex: 1;
  @media only screen and (max-width: 1079px) {
    margin-left: 16px;
  }
`;
export const Container = styled(FlexContainer)``;
export const MainWrapper = styled(FlexSection)``;
export const SliderWrapper = styled(FlexDiv)`
  flex-direction: column;
  margin: 0 -8px;
  .slick-prev,
  .slick-next {
    top: -28px;
    display: block !important;
    height: 32px;
    width: 32px;
    &:before {
      height: 100%;
      width: 100%;
      content: "";
      background: url(${({ arrowImage }) => `/images/${arrowImage}`}) center
        no-repeat;
      background-size: 100%;
      display: block;
      opacity: 1;
    }
    &.slick-disabled {
      &:before {
        opacity: 0.25;
      }
    }
    @media only screen and (max-width: 1023px) {
      height: 28px;
      width: 28px;
    }
    @media only screen and (max-width: 639px) {
      top: -20px;
    }
    @media only screen and (max-width: 395px) {
      top: -32px;
    }
  }
  .slick-next {
    right: 10px;
  }
  .slick-prev {
    left: unset;
    right: 48px;
    &:before {
      transform: rotateY(180deg);
    }
  }
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: auto !important;
  }
  .slick-dots {
    bottom: 0;
    li {
      margin: 0 4px;
      height: unset;
      width: unset;
      button {
        padding: 0;
        height: 8px;
        width: 8px;
        position: relative;
        &:before {
          content: "";
          transition: all 0.3s ease-in-out;
          background-color: ${({ theme }) => theme.colors.white};
          border-radius: 50%;
          height: 100%;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 1;
        }
      }
      &.slick-active {
        button {
          &:before {
            background-color: ${({ dotsColor, theme }) =>
              dotsColor || theme.colorsV2.shyft.purple_text};
          }
        }
      }
    }
  }
  .slick-list {
    padding: 40px 0 64px;
    @media only screen and (max-width: 1023px) {
      padding: 24px 0 24px;
    }
  }
  .slick-slide {
    & > div {
      display: flex;
      height: 100%;
      margin: 0 8px;
      @media only screen and (max-width: 1023px) {
        margin: 0;
        padding: 0 4px;
      }
    }
    &:last-child {
      @media only screen and (max-width: 1023px) {
        > div {
          padding-right: 8px;
        }
      }
    }
    &:first-child {
      @media only screen and (max-width: 1023px) {
        > div {
          padding-left: 8px;
        }
      }
    }
  }
  @media only screen and (max-width: 1023px) {
    margin: 0;
  }
`;
export const CardWrapper = styled(FlexDiv)`
  border: 1px solid ${({ theme }) => theme.colorsV2.gray.shade_200};
  border-radius: 16px;
  overflow: hidden;
  display: flex !important;
  background-color: ${({ theme }) => theme.colors.white};
`;
export const SliderImageHolder = styled(Image)``;
export const SliderImageBox = styled(FlexDiv)`
  width: 100%;
  max-width: 33.33%;
  min-width: 96px;
  height: 100%;
  margin-right: 16px;
  ${SliderImageHolder} {
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`;
export const RHSBox = styled(FlexDiv)`
  flex-direction: column;
  padding: 16px 8px;
`;
export const LogoImage = styled(Image)``;
export const SliderLogoBox = styled(ImageBox)`
  height: 56px;
  width: 56px;
  min-width: 56px;
  margin-bottom: 8px;
  @media only screen and (max-width: 1079px) {
    margin-bottom: 8px;
  }
`;
export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.primary.shade_700};
  margin-bottom: 8px;
`;
