import React from "react";
import PropTypes from "prop-types";

import { FlexDiv } from "@/components/Atoms/Box";
import Button from "@/components/Atoms/Button";

import { noop, propTypeChildren } from "@/utils";

import theme from "@/foundation/Theme";

export function SubmitButtonV2({
  children,
  buttonPadding,
  onClick,
  disabled,
  id,
  isLoading,
  variant,
  fontFamily,
  fontSize,
  version,
  width,
  minWidth,
  minHeight,
  boxShadow,
  borderRadius,
  bgColor = disabled
    ? `${theme.colorsV2.shyft.purple_text}2e`
    : theme.colorsV2.shyft.purple_text,
  hoverColor = bgColor,
  ...rest
}) {
  return (
    <FlexDiv
      justifyContent="flex-start"
      alignItems="flex-end"
      flex="1"
      {...rest}
    >
      <Button
        bgColor={bgColor}
        hoverColor={hoverColor}
        variant={variant}
        fontFamily={fontFamily}
        buttonPadding={buttonPadding}
        onClick={onClick}
        disabled={disabled}
        id={id}
        fontSize={fontSize}
        version={version}
        isLoading={isLoading}
        minWidth={minWidth}
        minHeight={minHeight}
        width={width}
        boxShadow={boxShadow}
        borderRadius={borderRadius}
        border={"0 !important"}
      >
        {children}
      </Button>
    </FlexDiv>
  );
}

SubmitButtonV2.propTypes = {
  children: propTypeChildren?.isRequired,
  buttonPadding: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  variant: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Array),
  ]),
  version: PropTypes.string,
  width: PropTypes.string,
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
  boxShadow: PropTypes.string,
  borderRadius: PropTypes.string,
};

SubmitButtonV2.defaultProps = {
  onClick: noop,
  disabled: false,
  id: "",
  isLoading: false,
  width: "100%",
  variant: "primary1",
  fontFamily: "var(--man-700)",
  fontSize: ["14px", "15px", "16px"],
  version: "v2",
  boxShadow: `0px 1px 2px rgba(${theme.colors.shadows.gray}, 0.05)`,
  minWidth: "123px",
  minHeight: "56px",
  borderRadius: "8px",
};
