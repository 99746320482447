import React from "react";
import PropTypes from "prop-types";

import SVGSprite from "@/components/Atoms/SVGSprite";

import theme from "@/foundation/Theme";
import Typography from "@/foundation/Typography/V2";

import { ErrorDiv } from "./style";

const InputError = ({
  error,
  color,
  variant,
  icon,
  iconDimension,
  mr,
  hasBottomBorder,
  isNotAbsolute,
  style,
}) => {
  return (
    <ErrorDiv
      hasBottomBorder={hasBottomBorder}
      alignItems="center"
      position={isNotAbsolute ? "static" : "absolute"}
      className="form-input-error"
      style={style}
    >
      <SVGSprite
        icon={icon}
        height={iconDimension}
        width={iconDimension}
        fill={color}
        mr={mr}
      />
      <Typography variant={variant} color={color} flex={1}>
        {error}
      </Typography>
    </ErrorDiv>
  );
};

InputError.propTypes = {
  error: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.string,
  iconDimension: PropTypes.string,
  mr: PropTypes.string,
  hasBottomBorder: PropTypes.bool,
};

InputError.defaultProps = {
  error: "",
  color: theme.colorsV2.shyft.error,
  variant: "label4",
  icon: "icon-info-circle-2",
  iconDimension: "16px",
  mr: "8px",
  hasBottomBorder: false,
};

export default InputError;
