import React from "react";

import { FlexDiv } from "@/components/Atoms/Box";
import SVGSprite from "@/components/Atoms/SVGSprite";

import theme from "@/foundation/Theme";

import { HowItWorks } from "../../style";
import {
  FlyBox,
  FlyWrapper,
  Heading,
  Icon,
  TextBox,
  SubHeading,
} from "./style";

export const TextWrap = (props) => {
  const { data } = props || {};
  return (
    <FlyBox position="relative" pl={"78px"} flexDirection="column">
      <Icon bgCol={data.color}>
        <SVGSprite
          icon={data.icon}
          height={"28px"}
          width={"28px"}
          fill={theme.colors.white}
        />
      </Icon>
      <Heading isExtraBold variant="display3" color={data.color}>
        {data.heading}
      </Heading>
      <SubHeading variant="heading6">{data.subheading}</SubHeading>
    </FlyBox>
  );
};
function TextSlider(props) {
  const { active, data } = props || {};
  const { sectionHeading, BANNER_DATA } = data;
  return (
    <FlyWrapper flexDirection={"column"} alignItems={"flex-start"}>
      <HowItWorks
        bg={theme.colorsV2.gray.shade_50}
        p={"7px 24px"}
        variant="heading6"
      >
        {sectionHeading}
      </HowItWorks>
      <FlexDiv flexDirection={"column"} flex={1}>
        {BANNER_DATA?.map((slideData, index) => {
          return (
            <TextBox
              flexDirection={"column"}
              justifyContent={"center"}
              key={index}
              mt={"100px"}
              mb={"250px"}
              minHeight={"420px"}
            >
              <FlyBox
                position="relative"
                flexDirection="column"
                className={`${
                  active === index + 1
                    ? "active"
                    : active - 1 === index + 1
                    ? "prev"
                    : active + 1 === index + 1
                    ? "prev"
                    : ""
                }`}
                id={`text-${index + 1}`}
              >
                <Icon bgCol={slideData.color}>
                  <SVGSprite
                    icon={slideData.icon}
                    height={"28px"}
                    width={"28px"}
                    fill={theme.colors.white}
                  />
                </Icon>
                <Heading
                  variant="display3"
                  color={slideData.color}
                  isExtraBold
                  mb={"8px"}
                >
                  {slideData.heading}
                </Heading>
                <SubHeading variant="heading6">
                  {slideData.subheading}
                </SubHeading>
              </FlyBox>
            </TextBox>
          );
        })}
      </FlexDiv>
    </FlyWrapper>
  );
}

export default TextSlider;
