import React from "react";

import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import Link from "./FooterLink";
import footerData from "./FooterData";

import { FooterBox, FooterContainer, Terms, FooterTop } from "./style";
import theme from "../../../foundation/Theme";

function Footer({ footerRef, dark = true }) {
  return (
    <FooterBox ref={footerRef} dark={dark} id="footer">
      <FooterContainer flexDirection="column">
        <FooterTop>
          <LeftSection dark={dark} />
          <RightSection dark={dark} />
        </FooterTop>
        <Terms
          dark={dark}
          color={dark ? theme.colors.white : theme.colorsV2.primary.shade_800}
        >
          {footerData?.terms?.map((link) => (
            <Link key={link.text} link={link} className="term" isTermLink />
          ))}
        </Terms>
      </FooterContainer>
    </FooterBox>
  );
}

export default Footer;
