import React from "react";

import { Box } from "@/components/Atoms/Box";
import Link from "../FooterLink";
import footerData from "../FooterData";

import theme from "@/foundation/Theme";
import Typography from "@/foundation/Typography/V2";

import { FooterSection, BrandBox } from "../style";
import { GridDiv, SocialIcons, Tagline, BrandLogo } from "./style";

function LeftSection({ dark }) {
  const categories = Object.keys(footerData.categories);

  return (
    <FooterSection>
      <BrandBox>
        <BrandLogo
          icon="icon-brand-logo-light"
          fill={dark ? theme.colors.white : theme.colors.brand_logo}
        />
        {footerData.tagline && (
          <Tagline variant="paragraph3" dark={dark}>
            {footerData.tagline}
          </Tagline>
        )}
      </BrandBox>
      <GridDiv dark={dark} grid={categories.length}>
        {categories.map((category) => (
          <Box key={category} className="category">
            <Typography
              variant="heading6"
              className="categoryTitle"
              fontSize={["12px", "14px", "16px"]}
              fontFamily="var(--man-800)"
              color={
                dark
                  ? theme.colorsV2.rose.shade_200
                  : theme.colorsV2.shyft.purple_text
              }
            >
              {category}
            </Typography>
            <Box className="categoryLinks">
              {footerData.categories[category].map((link) => (
                <Link key={link.text} className="categoryLink" link={link}>
                  {link.icon && (
                    <SocialIcons
                      icon={dark ? link.iconDark : link.icon}
                      mr={"8px"}
                    />
                  )}
                </Link>
              ))}
            </Box>
          </Box>
        ))}
      </GridDiv>
    </FooterSection>
  );
}

export default LeftSection;
