import styled from "styled-components";
import { motion } from "framer-motion";

import { Box, FlexDiv } from "@/components/Atoms/Box";
import Image from "@/components/Atoms/Image";

import theme from "@/foundation/Theme";

export const SvgWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  transform: translateY(190px);
  .ring {
    transition: all 1s ease-in-out;
    circle,
    radialGradient,
    stop {
      transition: all 1s ease-in-out;
    }
  }
  @media only screen and (max-width: 1079px) {
    transform: translateY(160px);
  }
  @media only screen and (max-width: 767px) {
    transform: translateY(160px);
  }
`;

export const StaticSvg = styled.svg`
  width: 100%;
  max-width: 100%;
  @media only screen and (max-width: 1079px) {
    max-width: 447px;
  }
`;

export const RotateSvg = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
    rotate(-${({ rotate }) => Math.round(rotate)}deg);
  transition: all 1s ease-in-out;
  circle,
  radialGradient,
  stop {
    transition: all 1s ease-in-out;
  }
  z-index: 2;
  width: 100%;
  max-width: 100%;
  @media only screen and (max-width: 1079px) {
    max-width: 447px;
  }
`;

export const MobileMockup = styled(FlexDiv)`
  border: 8px solid ${theme.colors.black};
  border-bottom: 0px;
  border-radius: 42px 42px 0 0;
  max-width: 300px;
  margin: 0 auto;
  max-height: 615px;
  height: 100%;
  background-color: ${theme.colors.black};
  position: absolute;
  bottom: -10px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  z-index: 3;
  overflow: hidden;
  display: flex;
  @media only screen and (max-width: 1079px) {
    max-width: 225px;
    max-height: 447px;
  }
  @media only screen and (max-width: 767px) {
    max-height: 447px;
    max-width: 225px;
  }
  @media only screen and (max-height: 700px) {
    max-height: calc(100vh - 80px);
  }
`;

export const ImageHolder = styled(Image)`
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

export const ImageBox = styled(motion.div)`
  position: relative;
  width: 100%;
  min-width: 284px;
`;
export const HiddenImageDiv = styled(FlexDiv)`
  flex-direction: column;
  display: none;
  position: relative;
`;
export const HiddenImage = styled(ImageHolder)`
  display: none;
  height: 0;
  width: 0;
`;
