import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";

import { Box, FlexDiv } from "@/components/Atoms/Box";
import Button from "@/components/Atoms/Button";

import { useAppSelector } from "@/hooks";
import { sentryLogs } from "@/utils";

const PAGE_REDIRECT_TIME = 5;

function NotFound() {
  const router = useRouter();
  const { pathname } = router;

  const isHomePage = pathname === "/";

  const [timeCounter, setTimeCounter] = useState(PAGE_REDIRECT_TIME);
  const { error } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (error !== "") {
      sentryLogs("error", error?.message, null, {
        PAGE_NOT_FOUND_ERROR: error?.message,
        [error?.code]: pathname,
      });
    }
  }, [error, pathname]);

  // useEffect(() => {
  //   let pageRedirectionTimeout = null;
  //   let pageTimeoutInterval = null;
  //   if (!isHomePage) {
  //     pageRedirectionTimeout = setTimeout(
  //       () => router.replace("/"),
  //       PAGE_REDIRECT_TIME * 1000
  //     );

  //     pageTimeoutInterval = setInterval(
  //       () =>
  //         setTimeCounter((previous) => {
  //           return previous && previous - 1;
  //         }),
  //       1000
  //     );
  //   }

  //   return () => {
  //     clearTimeout(pageRedirectionTimeout);
  //     clearInterval(pageTimeoutInterval);
  //   };
  // }, [isHomePage, router]);

  return (
    <FlexDiv
      flexDirection={"column"}
      flex={1}
      minHeight={"100vh"}
      p={"16px"}
      justifyContent="center"
      alignItems="center"
    >
      <Box className="heading1">OOPS!</Box>
      <Box className="heading2">404 - Page Not Found</Box>
      <p className="font-weight-bold mt-25 text-center">
        The page you are looking for might have been removed or temporarily
        unavailable.
      </p>

      {/* {!isHomePage && (
        <p className="font-weight-bold mt-25 mb-25 text-center">
          You will be automatically redirected to home page in{" "}
          <span style={{ color: "blue" }}>{timeCounter} seconds.</span>
        </p>
      )} */}

      {!isHomePage && (
        <Button onClick={() => router.replace("/")}>Go to Home</Button>
      )}
    </FlexDiv>
  );
}

export default NotFound;
