import styled from "styled-components";

import { FlexDiv } from "@/components/Atoms/Box";
import Image from "@/components/Atoms/Image";

import Typography from "@/foundation/Typography/V2";

export const ImageContainer = styled(FlexDiv)``;
export const TextContainer = styled(FlexDiv)``;
export const ImageHolder = styled(FlexDiv)`
  overflow: hidden;
  img {
    object-fit: cover;
  }
`;
export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.success.shade_600};
  text-align: inherit;
`;
export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.gray.shade_700};
  text-align: inherit;
`;
export const ContactUsSection = styled(FlexDiv)`
  margin-top: 24px;
  justify-content: center;
  @media only screen and (max-width: 399px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const ContactBlock = styled(FlexDiv)`
  flex-direction: column;
  text-align: inherit;
  margin-right: 40px;
  &:last-child {
    margin-right: 0;
  }
  @media only screen and (max-width: 424px) {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
  @media only screen and (max-width: 399px) {
    margin-right: 0px;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
export const ContactHeading = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.gray.shade_700};
`;
export const ContactValue = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.shyft.purple_text};
`;
export const ContactLink = styled(Typography)``;

export const ImageValue = styled(Image)``;

export const StyledSuccessModal = styled(FlexDiv)`
  align-items: ${({ contentAlign }) => contentAlign};
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  flex: 1;
  ${ImageContainer} {
    margin-bottom: 24px;
    justify-content: center;
  }
  ${TextContainer} {
    text-align: ${({ contentAlign }) =>
      contentAlign === "flex-start" ? "left" : "center"};
    flex-direction: column;
  }
`;
