import styled from "styled-components";

import { Box } from "@/components/Atoms/Box";
import SVGSprite from "@/components/Atoms/SVGSprite";

import Typography from "@/foundation/Typography/V2";
import theme from "@/foundation/Theme";

export const BrandLogo = styled(SVGSprite)`
  height: 32px;
  width: 70px;
`;

export const Tagline = styled(Typography)`
  margin-top: 8px;
  color: ${({ dark }) => (dark ? theme.colors.white : theme.colors.black)};
`;

export const GridDiv = styled(Box)`
  padding: 16px 24px;
  margin: 0 -8px;
  display: grid;
  grid-template-columns: repeat(${({ grid }) => grid}, 1fr);
  .category {
    padding: 0 8px;
    .categoryTitle {
      padding: 0 0 16px;
      text-transform: uppercase;
    }
    .categoryLinks {
      display: flex;
      flex-direction: column;
      .categoryLink {
        padding: 8px 0;
        color: ${({ dark }) =>
          dark
            ? theme.colorsV2.primary.shade_100
            : theme.colorsV2.gray.shade_600};
        text-decoration: none;
        .icon {
          height: 16px;
          width: 16px;
        }
      }
    }
  }
  @media only screen and (max-width: 1139px) {
    padding: 16px 0;
  }
`;

export const SocialIcons = styled(SVGSprite)`
  height: 16px;
  width: 16px;
  margin: 0 8px 0 0;
`;
