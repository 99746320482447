import React from "react";

import { ALink } from "@/components/Atoms/Box";

import Typography from "@/foundation/Typography/V2";

import { StyledLink } from "./style";

function LinkComp({ link, className, children, isTermLink, type }) {
  const renderComp = () => {
    if (link.link) {
      if (link.external) {
        return (
          <ALink
            className={className}
            href={link?.link?.endsWith("/") ? link?.link : `${link?.link}/`}
            target="_blank"
            color="inherit"
            display={"flex"}
            alignItems="center"
            fontSize="inherit"
          >
            <Typography variant="paragraph3" color="inherit">
              {children}
              {link.text}
            </Typography>
          </ALink>
        );
      }
      return (
        <StyledLink
          variant={link.type || "routelink1"}
          className={className}
          href={link?.link?.endsWith("/") ? link?.link : `${link?.link}/`}
          to={link.link}
          link1Color="inherit"
          fontSize="inherit"
        >
          <Typography
            variant={isTermLink ? "label4" : "paragraph3"}
            color="inherit"
          >
            {link.text}
          </Typography>
        </StyledLink>
      );
    } else {
      return (
        <Typography
          variant={isTermLink ? "label4" : "paragraph3"}
          color="inherit"
          className={className}
        >
          {link.text}
        </Typography>
      );
    }
  };

  return renderComp();
}

export default LinkComp;
