import React from "react";
import PropTypes from "prop-types";

import { FlexDiv, Label } from "@/components/Atoms/Box";
import InputError from "@/components/Organisms/FormInputs/InputError";

import { noop } from "@/utils";

import Typography from "@/foundation/Typography/V2";

import { Tags } from "./style";
import { InputGroup } from "../style";

function TagGroup({
  tagList,
  error,
  onClick,
  selectedIndex,
  color,
  activeColor,
  label,
  labelColor,
  mb,
}) {
  return (
    <FlexDiv flexDirection={"column"} mb={mb}>
      <Label>
        <Typography color={labelColor} variant="paragraph3" mb={"8px"}>
          {label}
        </Typography>
      </Label>
      <InputGroup showLabels={true} hasNoError>
        <FlexDiv>
          {tagList?.map((item, index) => {
            return (
              <Tags
                onClick={() => {
                  if (selectedIndex !== index) {
                    onClick(item, index);
                  }
                }}
                variant="label3"
                key={index}
                color={selectedIndex === index ? activeColor : color}
                isSelected={selectedIndex === index}
              >
                {item.label}
              </Tags>
            );
          })}
        </FlexDiv>
        {error && <InputError error={error} />}
      </InputGroup>
    </FlexDiv>
  );
}

TagGroup.propTypes = {
  tagList: PropTypes.instanceOf(Array),
  error: PropTypes.string,
  onClick: PropTypes.func,
  selectedIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  activeColor: PropTypes.string,
  label: PropTypes.string,
  labelColor: PropTypes.string,
};

TagGroup.defaultProps = {
  tagList: [],
  error: "",
  onClick: noop,
  selectedIndex: 0,
  color: "",
  activeColor: "",
  label: "",
  labelColor: "",
};

export default TagGroup;
