import styled from "styled-components";

import { FlexDiv } from "@/components/Atoms/Box";

export const ErrorDiv = styled(FlexDiv)`
  left: 0;
  top: calc(
    100% + ${({ hasBottomBorder }) => (hasBottomBorder ? "13px" : "8px")}
  );
`;
