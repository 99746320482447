import styled from "styled-components";

import { FlexDiv } from "@/components/Atoms/Box";

export const FormBox = styled(FlexDiv)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 24px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colorsV2.blueGray.shade_100};
  width: 100%;
  @media only screen and (max-width: 1079px) {
    box-shadow: 0px 16px 24px
        rgba(${({ theme }) => theme.colors.shadows.purple}, 0.08),
      0px 2px 6px rgba(${({ theme }) => theme.colors.shadows.purple}, 0.06),
      0px 0px 1px rgba(${({ theme }) => theme.colors.shadows.purple}, 0.04);
  }
`;

export const FormFieldBox = styled(FlexDiv)`
  flex-direction: column;
  padding: ${({ padding }) => padding};
  flex: 1;
  @media only screen and (max-width: 1079px) {
    padding: ${({ mobilePadding }) => mobilePadding};
  }
`;
