import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";
import NextImage from "next/image";

import MainTemplate from "@/components/Templates/MainTemplate";
import { Container, FlexDiv } from "@/components/Atoms/Box";
import CustomerStories from "@/components/Organisms/CustomerStories";
import HomeSlider from "@/components/Organisms/Slider/HomeSlider";

import HeroSection from "./HeroSection";
import RegisterModal from "./RegisterModal";
import SvgRing from "./SvgRing";
import TextSlider from "./SvgRing/TextSlider";
import HowItWorksMobile from "./SvgRing/MobileTemplate";

import { useWindowDimensions } from "@/hooks";
import theme from "@/foundation/Theme";

import { GlobalCss, HowItWorks, RingWrapper, Wrapper } from "./style";
import { HiddenImageDiv } from "./SvgRing/style";

import {
  mapCustomerStories,
  mapHeroSectionData,
  mapWellnessConditionData,
  mapRegisterModalData,
  mapHowItWorksSection,
} from "./mapLandingPageData";

function HomePageNew({ data: homePageData }) {
  const { pathname } = useRouter();
  const { width } = useWindowDimensions();

  let urlParams = "";
  if (typeof window !== "undefined") {
    urlParams = window.location.search;
  }

  const [active, setActive] = useState(1);
  const [showModal, setShowModal] = useState(false);

  const { data, isLoading, error } = homePageData || {};

  useEffect(() => {
    const text1Ref = document.getElementById("text-1");
    const text2Ref = document.getElementById("text-2");
    const text3Ref = document.getElementById("text-3");
    const text4Ref = document.getElementById("text-4");
    const text5Ref = document.getElementById("text-5");

    if (width > 1079) {
      const detectScroll = () => {
        let count = active;
        window.onscroll = () => {
          let windowHeight = window.innerHeight;

          let topMargin =
            windowHeight >= 655 ? Math.round(windowHeight * 0.1) : 0;
          let screenTop = 0 + topMargin;

          let text1 = text1Ref?.getBoundingClientRect();
          let text2 = text2Ref?.getBoundingClientRect();
          let text3 = text3Ref?.getBoundingClientRect();
          let text4 = text4Ref?.getBoundingClientRect();
          let text5 = text5Ref?.getBoundingClientRect();

          if (
            windowHeight > text1?.bottom - windowHeight &&
            screenTop < text1?.top
          ) {
            text1Ref.style.opacity = "1";
            text2Ref.style.opacity = "0";
            text3Ref.style.opacity = "0";
            text4Ref.style.opacity = "0";
            text5Ref.style.opacity = "0";
            count = 1;
            if (active !== count) setActive(count);
          } else if (
            windowHeight > text2?.bottom - windowHeight &&
            screenTop < text2?.top
          ) {
            if (text2?.bottom - windowHeight <= 0) {
              text2Ref.style.opacity = "1";
              count = 2;
              if (active !== count) setActive(count);
            } else {
              text2Ref.style.opacity = "0";
              if (active !== count) setActive(count);
            }
            text1Ref.style.opacity = "0";
            text3Ref.style.opacity = "0";
            text4Ref.style.opacity = "0";
            text5Ref.style.opacity = "0";
          } else if (
            windowHeight > text3?.bottom - windowHeight &&
            screenTop < text3?.top
          ) {
            if (text3?.bottom - windowHeight <= 0) {
              text3Ref.style.opacity = "1";
              count = 3;
              if (active !== count) setActive(count);
            } else {
              text3Ref.style.opacity = "0";
              if (active !== count) setActive(count);
            }
            text1Ref.style.opacity = "0";
            text2Ref.style.opacity = "0";
            text4Ref.style.opacity = "0";
            text5Ref.style.opacity = "0";
          } else if (
            windowHeight > text4?.bottom - windowHeight &&
            screenTop < text4?.top
          ) {
            if (text4?.bottom - windowHeight <= 0) {
              text4Ref.style.opacity = "1";
              count = 4;
              if (active !== count) setActive(count);
            } else {
              text4Ref.style.opacity = "0";
              if (active !== count) setActive(count);
            }
            text1Ref.style.opacity = "0";
            text2Ref.style.opacity = "0";
            text3Ref.style.opacity = "0";
            text5Ref.style.opacity = "0";
          } else if (
            windowHeight > text5?.bottom - windowHeight &&
            screenTop < text5?.top
          ) {
            if (text5?.bottom - windowHeight <= 0) {
              text5Ref.style.opacity = "1";
              count = 5;
              if (active !== count) setActive(count);
            } else {
              text5Ref.style.opacity = "0";
              if (active !== count) setActive(count);
            }
            text1Ref.style.opacity = "0";
            text2Ref.style.opacity = "0";
            text3Ref.style.opacity = "0";
            text4Ref.style.opacity = "0";
          }
        };
      };
      detectScroll();
    }
  });

  return (
    <MainTemplate
      // isLoading={isLoading}
      error={error}
      // hideHeader
      showTransparentHeader
      darkHeader
    >
      <HeroSection
        data={mapHeroSectionData(data)}
        width={width}
        setShowModal={setShowModal}
      />
      {mapCustomerStories(data).storyData?.length > 0 && (
        <CustomerStories data={mapCustomerStories(data)} />
      )}
      {mapWellnessConditionData(data)?.components?.length > 0 && (
        <HomeSlider data={mapWellnessConditionData(data)} />
      )}
      <Wrapper>
        <Container
          display="flex"
          alignItems={width <= 1079 ? "center" : ""}
          justifyContent={"space-between"}
          position={"relative"}
          flexDirection={width <= 1079 ? "column" : "row"}
          px={width <= 1079 ? "0px !important" : ""}
        >
          {width <= 1079 && (
            <HowItWorks
              bg={width <= 1079 ? "" : theme.colorsV2.gray.shade_50}
              p={width <= 1079 ? "17px 0 0 0" : "7px 24px"}
              variant="heading6"
            >
              {mapHowItWorksSection(data)?.sectionHeading}
            </HowItWorks>
          )}
          {width > 1079 && (
            <>
              <TextSlider active={active} data={mapHowItWorksSection(data)} />
              <FlexDiv alignItems={"flex-end"}>
                <FlexDiv
                  position="sticky"
                  bottom="0"
                  justifyContent={"flex-end"}
                >
                  <RingWrapper
                    maxWidth={"100%"}
                    overflow={"hidden"}
                    position="relative"
                  >
                    <SvgRing
                      active={active}
                      width={width}
                      data={mapHowItWorksSection(data)}
                    />
                  </RingWrapper>
                </FlexDiv>
              </FlexDiv>
            </>
          )}
          {width <= 1079 && (
            <HowItWorksMobile width={width} data={mapHowItWorksSection(data)} />
          )}
          {width > 1079 && (
            <HiddenImageDiv>
              {mapHowItWorksSection(data)?.BANNER_DATA?.map((image, index) => (
                <NextImage
                  fill
                  style={{ objectFit: "cover" }}
                  key={index}
                  src={image?.image}
                  alt="hidden"
                />
              ))}
            </HiddenImageDiv>
          )}
        </Container>
      </Wrapper>
      <AnimatePresence>
        {showModal && (
          <RegisterModal
            urlParams={urlParams}
            width={width}
            setShowModal={setShowModal}
            {...mapRegisterModalData(data, pathname)}
          />
        )}
      </AnimatePresence>
      <GlobalCss />
    </MainTemplate>
  );
}

export default HomePageNew;
