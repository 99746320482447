import styled from "styled-components";

import { FlexDiv } from "@/components/Atoms/Box";
import Image from "@/components/Atoms/Image";
import SVGSprite from "@/components/Atoms/SVGSprite";
import Modal from "@/components/Molecules/Modal";
import { TwoColumnModal } from "@/components/Organisms/TwoColumnSection";
import { FormFieldBox } from "@/components/Organisms/FormWrapper/style";

import Typography from "@/foundation/Typography/V2";

export const StyledModal = styled(Modal)`
  padding: 0;
  border-radius: 16px 16px 0px 0px;
  max-height: 80vh;
  .body-wrapper {
    padding: 0;
    border-radius: 16px 16px 0px 0px;
    width: 100%;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const CloseText = styled(Typography)`
  display: flex;
  align-items: center;
  top: 32px;
  left: 8px;
  color: ${({ theme }) => theme.colorsV2.gray.shade_700};
  position: absolute;
  @media only screen and (max-width: 767px) {
    top: 24px;
    left: 32px;
  }
`;
export const InnerColumn = styled(FlexDiv)`
  padding: 8px;
  margin-bottom: 8px;
  text-align: center;
  position: relative;
  justify-content: center;
  @media only screen and (max-width: 767px) {
    padding: 32px 24px;
    text-align: left;
  }
`;
export const Title = styled(Typography)`
  margin: 0px 0 8px;
  color: ${({ theme }) => theme.colorsV2.shyft.purple_text};
  @media only screen and (max-width: 767px) {
    margin: 0px 0 16px;
  }
`;

export const StyledTwoColumnModal = styled(TwoColumnModal)`
  background-color: transparent;
  flex-direction: column;
  padding: 48px 32px 32px;
  border: none;
  @media only screen and (max-width: 767px) {
    border-radius: 24px 24px 0 0;
    padding: 0;
    max-height: calc(100vh - 80px);
    overflow: hidden auto;
  }
  .left-section {
    padding: 0;
    flex: 1;
    ${CloseText} {
      cursor: pointer;
    }
    @media only screen and (max-width: 767px) {
      max-width: 100%;
    }
  }
  .right-section {
    max-width: 100%;
    width: 100%;
    padding: 0;
    @media only screen and (max-width: 767px) {
      max-width: 100%;
      > div {
        border-radius: 24px 24px 0 0;
      }
    }
  }
`;

export const CloseIcon = styled(SVGSprite)`
  position: absolute;
  cursor: pointer;
  top: 8px;
  right: 4px;
  fill: ${({ theme }) => theme.colorsV2.gray.shade_500};
  z-index: 1;
  padding: 8px;
  height: 32px;
  width: 32px;
`;
export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.gray.shade_700};
`;
export const ContactUsSection = styled(FlexDiv)`
  margin-top: 24px;
`;
export const ContactBlock = styled(FlexDiv)`
  flex-direction: column;
  margin-right: 40px;
  &:last-child {
    margin-right: 0;
  }
  @media only screen and (max-width: 424px) {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
export const ContactHeading = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.gray.shade_700};
`;
export const ContactValue = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.shyft.purple_text};
`;
export const ContactLink = styled(Typography)``;
export const LHSSection = styled(FlexDiv)``;
export const StyledFormFieldBox = styled(FormFieldBox)`
  .joinCommunityToggle {
    label {
      display: flex;
      align-items: center;
      padding: 12px;
      border-radius: 10px;
      border: 1px solid #0000001a;
      gap: 24px;
      background-color: #fff;
    }
  }
  ${({ isFormSubmitted, theme }) =>
    !isFormSubmitted
      ? `
      box-shadow: 0px 0px 6px
          rgba(${theme.colors.shadows.black}, 0.02),
        0px 2px 4px rgba(${theme.colors.shadows.black}, 0.08);
      background-color: ${theme.colorsV2.gray.shade_100};
      padding: 32px;
      border-radius: 16px;
      border: 1px solid ${theme.colorsV2.gray.shade_200};
      `
      : `
      background-color:${theme.colors.white};
      padding: 32px 0px 40px 0px;
      @media only screen and (max-width: 767px) {
        padding: 32px 40px 40px 24px;
      }
      `}
  @media only screen and (max-width:767px) {
    box-shadow: none;
  }
`;
export const FormImage = styled(Image)``;
export const FormImageBox = styled(FlexDiv)`
  width: 100%;
  max-width: 124px;
  height: 112px;
  margin-bottom: 24px;
`;
