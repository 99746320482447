import styled from "styled-components";

import { FlexContainer, FlexDiv, FlexSection } from "@/components/Atoms/Box";

export const Wrapper = styled(FlexSection)`
  padding: 45px 0 64px;
  @media only screen and (max-width: 1079px) {
    padding: 20px 0;
    background-color: ${({ theme }) => theme.colorsV2.blueGray.shade_25};
  }
`;

export const WrapperContainer = styled(FlexContainer)`
  @media only screen and (max-width: 1079px) {
    padding: 0 8px;
  }
`;

export const CardBox = styled(FlexDiv)`
  padding: 16px;
  width: 100%;
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.colorsV2.blueGray.shade_100};
  box-shadow: 0px 16px 24px
      rgba(${({ theme }) => theme.colors.shadows.purple}, 0.08),
    0px 2px 6px rgba(${({ theme }) => theme.colors.shadows.purple}, 0.06),
    0px 0px 1px rgba(${({ theme }) => theme.colors.shadows.purple}, 0.04);
  background-color: ${({ theme }) => theme.colorsV2.blueGray.shade_50};
  @media only screen and (max-width: 1079px) {
    flex-direction: column;
    background-color: transparent;
    border-radius: 0px;
    border: 0px;
    box-shadow: none;
    padding: 0px;
  }
`;

export const LeftSection = styled(FlexDiv)`
  padding: 56px 38px 56px 40px;
  max-width: 540px;
  width: 100%;
  @media only screen and (max-width: 1079px) {
    margin: 0 auto;
    max-width: 714px;
    padding: 42px 24px 24px;
  }
`;

export const RightSection = styled(FlexDiv)`
  flex: 1;
  padding-left: 38px;
  max-width: 714px;
  margin: 0 auto;
  width: 100%;
  @media only screen and (max-width: 1079px) {
    padding-left: 0;
    margin: 0 auto;
  }
`;
