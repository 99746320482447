import styled, { createGlobalStyle } from "styled-components";

import {
  FlexSection,
  FlexContainer,
  FlexDiv,
  Box,
} from "@/components/Atoms/Box";
import SVGSprite from "@/components/Atoms/SVGSprite";
import Button from "@/components/Atoms/Button";

import Typography from "@/foundation/Typography/V2";

export const Wrapper = styled(FlexSection)`
  margin-top: ${({ topDistance }) => -topDistance + "px"};
  flex-direction: column;
  background: linear-gradient(
      180deg,
      ${({ theme }) => theme.colorsV2.primary.shade_800} 0%,
      ${({ theme }) => theme.colorsV2.primary.shade_200} 100%
    ),
    ${({ theme }) => theme.colorsV2.blueGray.shade_100};
  padding: 116px 0 64px;
  position: relative;
  > div {
    position: relative;
    z-index: 1;
  }
  &:after {
    content: "";
    position: absolute;
    height: 25%;
    background-color: ${({ theme }) => theme.colorsV2.gray.shade_50};
    z-index: 0;
    pointer-events: none;
    width: 100%;
    left: 0;
    bottom: 0;
  }
  @media only screen and (max-width: 1279px) {
    padding: ${({ topDistance }) => topDistance + "px"} 0px 40px;
    &:after {
      content: normal;
    }
  }
`;

export const TopContainer = styled(FlexContainer)`
  flex-direction: column;
  padding: 0 40px;
  @media only screen and (max-width: 1079px) {
    padding: 0;
  }
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.primary.shade_25};
  text-align: center;
  * {
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    color: inherit;
  }
  .white {
    color: ${({ theme }) => theme.colors.white};
    display: block;
    margin-top: 8px;
  }
  @media only screen and (min-height: 750px) {
    font-size: 50px;
    line-height: 64px;
  }
  @media only screen and (min-height: 800px) {
    font-size: 52px;
    line-height: 68px;
  }
  @media only screen and (min-height: 850px) {
    font-size: 54px;
    line-height: 70px;
  }
`;
export const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-family: var(--man-700);
  margin-top: 10px;
  * {
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    color: inherit;
  }
  .white {
    color: ${({ theme }) => theme.colors.white};
    display: block;
    margin-top: 8px;
  }
  @media only screen and (min-height: 750px) {
    font-size: 30px;
    line-height: 32px;
  }
  @media only screen and (min-height: 800px) {
    font-size: 30px;
    line-height: 32px;
  }
  @media only screen and (min-height: 850px) {
    font-size: 30px;
    line-height: 32px;
  }
`;
export const Subtitle = styled(Typography)`
  margin: 56px 0px;
  text-align: center;
  font-family: var(--man-700);
  color: ${({ theme }) => theme.colors.white};
  * {
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    color: inherit;
  }
  .white {
    color: ${({ theme }) => theme.colorsV2.primary.shade_100};
  }
  .off-white {
    color: ${({ theme }) => theme.colorsV2.primary.shade_25};
  }
  @media only screen and (max-width: 1279px) {
    margin: 16px 0 40px;
  }
  @media only screen and (min-height: 750px) {
    font-size: 30px;
    line-height: 40px;
    margin: 30px 0 50px;
  }
  @media only screen and (min-height: 800px) {
    font-size: 32px;
    line-height: 46px;
    margin: 35px 0 55px;
  }
  @media only screen and (min-height: 850px) {
    font-size: 36px;
    line-height: 50px;
    margin: 40px 0 60px;
  }
`;
export const PrimaryCTA = styled(Button)`
  margin-right: 8px;
  padding: 11px 32px;
  box-shadow: 0px 10px 20px
      rgba(${({ theme }) => theme.colors.shadows.purple_3}, 0.18),
    0px 2px 6px rgba(${({ theme }) => theme.colors.shadows.purple_3}, 0.12),
    0px 0px 1px rgba(${({ theme }) => theme.colors.shadows.black}, 0.04);
  &:last-child {
    margin-right: 0;
  }
  @media only screen and (max-width: 992px) {
    padding: 9px 24px;
  }
`;
export const SecondaryCTA = styled(PrimaryCTA)`
  padding-right: 16px;
  padding-left: 40px;
  p {
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 992px) {
    padding: 9px 16px 9px 24px;
  }
`;
export const ButtonDiv = styled(FlexDiv)`
  margin-bottom: 40px;
  justify-content: center;
  @media only screen and (max-width: 1279px) {
    margin-bottom: 44px;
  }
`;
export const FeaturesDiv = styled(FlexDiv)`
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
  @media only screen and (max-width: 1023px) {
    margin-bottom: 24px;
    justify-content: space-between;
    padding: 0 24px;
  }
`;
export const FeatureItem = styled(FlexDiv)`
  flex-direction: column;
  position: relative;
  padding: 0 40px;
  margin-top: 8px;
  margin-right: 40px;
  max-width: 236px;
  width: 100%;
  &:last-child {
    margin-right: 0;
    &:after {
      content: normal;
    }
  }
  &:after {
    content: "";
    border-right: 1px solid ${({ theme }) => theme.colorsV2.gray.shade_300};
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-height: calc(100% - 8px);
    height: 100%;
  }
  @media only screen and (max-width: 992px) {
    margin-top: 0;
    max-width: 160px;
    margin-right: 8px;
    padding: 0 0 0 40px;
    max-width: calc(33.33% - 8px);
    &:last-child {
      margin-right: 0px;
    }
  }
  @media only screen and (max-width: 767px) {
    &:after {
      content: normal;
    }
    max-width: calc(50% - 8px);
  }
`;
export const FeatureTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.white};
`;
export const FeatureSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.white};
`;
export const Stars = styled(SVGSprite)`
  fill: ${({ theme }) => theme.colorsV2.warning.shade_300};
  height: 32px;
  width: 32px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;
export const Span = styled(Typography)`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 24px;
  background-color: ${({ theme }) => theme.colorsV2.primary.shade_800};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
export const ArrowRight = styled(SVGSprite)`
  fill: ${({ theme }) => theme.colors.white};
  height: 16px;
  width: 16px;
`;
export const OverrideHeaderStyle = createGlobalStyle`
header{
  position:relative;
}
`;
export const InnerTop = styled(FlexDiv)`
  flex-direction: column;
  margin-top: 48px;
  @media only screen and (max-width: 1279px) {
    padding: 40px 8px 16px;
    margin-bottom: 24px;
  }
  @media only screen and (min-height: 850px) {
    min-height: min(65vh, 600px);
    justify-content: space-around;
  }
  @media only screen and (max-width: 600px) {
    margin-top: 0;
  }
`;

export const DesktopServiceWrapper = styled(Box)`
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const MobileServiceWrapper = styled(Box)`
  @media only screen and (min-width: 1200px) {
    display: none;
  }
`;
