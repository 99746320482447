import React, { useEffect, useState } from "react";

import { AnimateDirection } from "@/components/Molecules/Modal";
import RegisterForm from "@/components/Organisms/RegisterForm";
import SuccessModalV2 from "@/components/Organisms/SuccessModal/V2/ModalV2";

import { timezone } from "@/utils";
import { DEFAULT_URL_ARGUMENTS } from "@/utils/Constants";
import { useAppSelector, useAppDispatch, useRegisterForm } from "@/hooks";

import * as actions from "@/redux/LandingPageReducer";

import {
  StyledModal,
  StyledTwoColumnModal,
  Title,
  InnerColumn,
  Subtitle,
  ContactUsSection,
  ContactBlock,
  ContactHeading,
  ContactValue,
  ContactLink,
  LHSSection,
  StyledFormFieldBox,
  CloseIcon,
} from "./style";

function RegisterModal({
  setShowModal,
  urlParams,
  defaultPackageSlug,
  title,
  subTitle,
  type,
  width,
  successImage,
  submissionTitle,
  submissionSubtitle,
  submissionExtraText,
  callUs,
  contact,
  writeToUs,
  emailUs,
  formDropdown,
}) {
  const dispatch = useAppDispatch();
  const { countryCodeData } = useAppSelector((state) => state.dashboard);

  const [joinCommunity, setJoinCommunity] = useState(true);

  const {
    isLoading: isThisPageLoading,
    handleBlur,
    handleChange,
    handleSelectChange,
    onSubmitCallback,
    inputs,
    showSuccessModal,
    onShowCallbackModal,
    onHideCallbackModal,
    isAllInputsFilled,
  } = useRegisterForm({
    name: "",
    countryCode: countryCodeData?.default?.code || "+91",
    mobile: "",
    goal: "",
  });

  const { name, countryCode, mobile, goal } = inputs;

  const onHide = () => setShowModal(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (showSuccessModal && !error) {
      setTimeout(() => onHideCallbackModal(onHide), 6000);
    }
  }, [showSuccessModal]);

  const leftSection = (
    <LHSSection flexDirection="column" width="100%">
      <InnerColumn flexDirection="column" flex="1">
        <Title variant="heading4" isExtraBold>
          {title}
        </Title>
        <Subtitle variant="paragraph1">{subTitle}</Subtitle>
        {false && (contact || emailUs) && (
          <ContactUsSection>
            {contact && (
              <ContactBlock>
                <ContactHeading variant="paragraph2">{callUs}</ContactHeading>
                <ContactLink href={`tel:${contact}`} variant="link1">
                  <ContactValue variant="label1">{contact}</ContactValue>
                </ContactLink>
              </ContactBlock>
            )}
            {emailUs && (
              <ContactBlock>
                <ContactHeading variant="paragraph2">
                  {writeToUs}
                </ContactHeading>
                <ContactLink href={`mailto:${emailUs}`} variant="link1">
                  <ContactValue variant="label1">{emailUs}</ContactValue>
                </ContactLink>
              </ContactBlock>
            )}
          </ContactUsSection>
        )}
      </InnerColumn>
    </LHSSection>
  );

  const urlsArguments = urlParams || DEFAULT_URL_ARGUMENTS;

  const onBlur = () => {
    const apiData = {
      name,
      mobile,
      phone_prefix: countryCode,
      timezone,
      package_slug: goal || defaultPackageSlug,
      urls_arguments: urlsArguments,
      is_lead: true,
    };

    dispatch(actions.saveLead({ data: apiData }));
  };

  const onSubmit = () => {
    let apiData = {
      name: name,
      mobile: mobile,
      phone_prefix: countryCode || "+91",
      type: type,
      package_slug: goal,
      urls_arguments: urlsArguments,
      timezone: timezone,
      is_callback_request: true,
      join_whatsapp_community: joinCommunity,
    };

    setIsLoading(true);
    return dispatch(actions.saveLead({ data: apiData }))
      .then(() => {
        setIsLoading(false);
        onShowCallbackModal();
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err);
      });
  };

  const onSubmitButtonClick = (e) => {
    e.preventDefault();
    onSubmitCallback(true, onSubmit);
  };

  const rightSection = (
    <StyledFormFieldBox isFormSubmitted={showSuccessModal}>
      {!showSuccessModal && (
        <RegisterForm
          isLoading={isLoading && isThisPageLoading}
          showLabels={false}
          {...inputs}
          error={error}
          onSubmitButtonClick={onSubmitButtonClick}
          handleBlur={(e) => handleBlur(e, onBlur)}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
          // Name
          namePlaceholder="Your Name"
          // Phone
          phonePlaceholder="Your Phone"
          // Button
          buttonText={"Submit"}
          buttonPadding="15px 32px"
          //goal
          showGoalsField
          goalPlaceholder="Select condition"
          goalOptions={formDropdown}
          onGoalChange={(value) => handleSelectChange("goal", value.slug)}
          goal={
            goal ? formDropdown?.find((option) => option.slug === goal) : null
          }
          homePageButton
          isAllInputsFilled={isAllInputsFilled}
          showJoinCommunityToggle
          joinCommunity={joinCommunity}
          setJoinCommunity={setJoinCommunity}
        />
      )}
      {showSuccessModal && (
        <SuccessModalV2
          successImage={successImage}
          submissionTitle={submissionTitle}
          submissionSubtitle={submissionSubtitle}
          submissionExtraText={submissionExtraText}
          contact={contact}
          emailUs={emailUs}
          writeToUs={writeToUs}
          callUs={callUs}
        />
      )}
    </StyledFormFieldBox>
  );

  return (
    <StyledModal
      overlayClosable
      onClose={() => setShowModal(false)}
      width={width <= 767 ? "100%" : "512px"}
      bottomYpos="0px"
      animateDirection={
        width <= 767 ? AnimateDirection.BOTTOM : AnimateDirection.CENTER
      }
      initialYpos={"-220%"}
      borderRadius={width <= 767 ? "16px 16px 0 0" : "16px"}
    >
      <CloseIcon
        icon="icon-close-regular"
        onClick={() => setShowModal(false)}
      />
      <StyledTwoColumnModal
        leftSection={!showSuccessModal && leftSection}
        rightSection={rightSection}
      />
    </StyledModal>
  );
}

export default RegisterModal;
