import styled from "styled-components";
import Typography from "../../../../foundation/Typography/V2";

import { InputGroup } from "../style";

export const SelectGroup = styled(InputGroup)``;
export const GoalLabel = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.gray.shade_700};
  margin-bottom: 8px;
`;
