import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import NextImage from "next/image";

import Image from "@/components/Atoms/Image";

import {
  StaticSvg,
  ImageBox,
  ImageHolder,
  MobileMockup,
  RotateSvg,
  SvgWrapper,
} from "./style";

function SvgRing(props) {
  const { active, width, data = {}, imageMobile } = props || {};
  const { BANNER_DATA } = data;
  const [isChanging, setIsChanging] = useState(false);
  const [prevSlide, setPrevSlide] = useState(active);
  const getImage = (active) => {
    switch (active) {
      case 1:
        return BANNER_DATA?.[0]?.image; //red
      case 2:
        return BANNER_DATA?.[1]?.image; //green
      case 3:
        return BANNER_DATA?.[2]?.image; //purple
      case 4:
        return BANNER_DATA?.[3]?.image; // blue
      case 5:
        return BANNER_DATA?.[4]?.image; //track-progress
      default:
        return BANNER_DATA?.[0]?.image; //red
    }
  };

  const getMobileSVG = (active) => {
    switch (active) {
      case 1:
        return "mobile-4.svg"; //light green
      case 2:
        return "mobile-3.svg"; //green
      case 3:
        return "mobile-2.svg"; //purple
      case 4:
        return "mobile-1.svg"; //blue
      case 5:
        return "mobile-5.svg"; //track-progress
      default:
        return "mobile-4.svg"; //red
    }
  };

  const getRings = (active) => {
    switch (active) {
      case 1:
        return { stopColor1: "#97D631", stopColor2: "#F6FBE7 " }; //light green
      case 2:
        return { stopColor1: "#00925D", stopColor2: "#D9FFE1" }; //green
      case 3:
        return { stopColor1: "#330EC0", stopColor2: "#D4C9FF" }; //purple
      case 4:
        return { stopColor1: "#227AFF", stopColor2: "#D1E9FF" }; //blue
      case 5:
        return { stopColor1: "#3CABEE", stopColor2: "#D1E9FF" }; // track progress
      default:
        return { stopColor1: "#E57E79", stopColor2: "#FFDAD8" }; //red
    }
  };

  const getRotation = (active) => {
    switch (active) {
      case 1:
        return 0;
      case 2:
        return 3;
      case 3:
        return 7;
      case 4:
        return 10;
      case 5:
        return 12;
      default:
        return 0;
    }
  };

  const getAltText = (active) => {
    switch (active) {
      case 1:
        return BANNER_DATA?.[0]?.altText; //red
      case 2:
        return BANNER_DATA?.[1]?.altText; //green
      case 3:
        return BANNER_DATA?.[2]?.altText; //purple
      case 4:
        return BANNER_DATA?.[3]?.altText; // blue
      case 5:
        return BANNER_DATA?.[4]?.altText; //track-progress
      default:
        return BANNER_DATA?.[0]?.altText; //red
    }
  };

  useEffect(() => {
    setIsChanging(true);
    setTimeout(() => {
      setPrevSlide(active);
      setIsChanging(false);
    }, 305);
  }, [active]);

  return (
    <>
      <MobileMockup id={width > 1079 ? "mobile-frame" : ""}>
        {width > 1079 ? (
          <>
            <AnimatePresence>
              {isChanging ? (
                <>
                  <ImageBox
                    initial={{ x: 0 }}
                    animate={{ x: -284 }}
                    exit={{ x: -284 }}
                    transition={{
                      ease: "easeOut",
                      duration: 0.3,
                    }}
                  >
                    <NextImage
                      src={getImage(prevSlide)}
                      alt={getAltText(prevSlide)}
                      fill
                      style={{ objectFit: "cover" }}
                    />
                  </ImageBox>
                  <ImageBox
                    initial={{ x: 0 }}
                    animate={{ x: -284 }}
                    exit={{ x: -284 }}
                    transition={{
                      ease: "easeOut",
                      duration: 0.3,
                    }}
                  >
                    <NextImage
                      src={getImage(active)}
                      alt={getAltText(active)}
                      fill
                      style={{ objectFit: "cover" }}
                    />
                  </ImageBox>
                </>
              ) : (
                <NextImage
                  src={getImage(active)}
                  alt={getAltText(active)}
                  fill
                  style={{ objectFit: "cover" }}
                />
              )}
            </AnimatePresence>
          </>
        ) : (
          <NextImage
            src={imageMobile}
            className={"active"}
            fill
            style={{ objectFit: "cover" }}
          />
        )}
      </MobileMockup>
      <SvgWrapper position="relative" overflow="hidden">
        {width > 1079 && (
          <>
            <RotateSvg
              width="707"
              height="707"
              viewBox="0 0 707 707"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={"rotate"}
              rotate={getRotation(width <= 1079 ? 1 : active)}
            >
              <circle
                cx="640.816"
                cy="354.926"
                r="16.2438"
                fill="url(#paint0_radial_979_2834)"
              />
              <circle
                cx="129.95"
                cy="174.62"
                r="16.2438"
                fill="url(#paint1_radial_979_2834)"
              />
              <circle
                cx="168.123"
                cy="396.348"
                r="8.93407"
                fill="url(#paint2_radial_979_2834)"
              />
              <circle
                cx="535.232"
                cy="296.449"
                r="8.93407"
                fill="url(#paint3_radial_979_2834)"
              />
              <defs>
                <radialGradient
                  id="paint0_radial_979_2834"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(642.963 365.139) rotate(-90.6058) scale(24.7218)"
                >
                  <stop
                    offset="0.536458"
                    stopColor={getRings(active).stopColor1}
                  />
                  <stop offset="1" stopColor={getRings(active).stopColor2} />
                </radialGradient>
                <radialGradient
                  id="paint1_radial_979_2834"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(132.097 184.833) rotate(-90.6058) scale(24.7218)"
                >
                  <stop
                    offset="0.536458"
                    stopColor={getRings(active).stopColor1}
                  />
                  <stop offset="1" stopColor={getRings(active).stopColor2} />
                </radialGradient>
                <radialGradient
                  id="paint2_radial_979_2834"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(169.304 401.965) rotate(-90.6058) scale(13.597)"
                >
                  <stop
                    offset="0.536458"
                    stopColor={getRings(active).stopColor1}
                  />
                  <stop offset="1" stopColor={getRings(active).stopColor2} />
                </radialGradient>
                <radialGradient
                  id="paint3_radial_979_2834"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(536.413 302.066) rotate(-90.6058) scale(13.597)"
                >
                  <stop
                    offset="0.536458"
                    stopColor={getRings(active).stopColor1}
                  />
                  <stop offset="1" stopColor={getRings(active).stopColor2} />
                </radialGradient>
              </defs>
            </RotateSvg>
            <StaticSvg
              width="707"
              height="707"
              viewBox="0 0 707 707"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={"ring"}
            >
              <circle
                cx="353.302"
                cy="353.698"
                r="353.302"
                fill="url(#paint0_radial_282_230)"
              />
              <circle
                cx="353.302"
                cy="353.698"
                r="287.514"
                fill="url(#paint1_radial_282_230)"
              />
              <circle
                cx="353.302"
                cy="353.698"
                r="190.052"
                fill="url(#paint2_radial_282_230)"
              />
              <circle
                cx="640.816"
                cy="355.323"
                r="16.2438"
                fill="url(#paint3_radial_282_230)"
              />
              <circle
                cx="129.95"
                cy="175.017"
                r="16.2438"
                fill="url(#paint4_radial_282_230)"
              />
              <circle
                cx="168.123"
                cy="396.744"
                r="8.93407"
                fill="url(#paint5_radial_282_230)"
              />
              <circle
                cx="535.232"
                cy="296.845"
                r="8.93407"
                fill="url(#paint6_radial_282_230)"
              />
              <circle
                cx="353.302"
                cy="353.302"
                r="353.302"
                fill="url(#paint7_radial_282_230)"
              />
              <circle
                cx="353.302"
                cy="353.302"
                r="287.514"
                fill="url(#paint8_radial_282_230)"
              />
              <circle
                cx="353.302"
                cy="353.302"
                r="190.052"
                fill="url(#paint9_radial_282_230)"
              />
              <defs>
                <radialGradient
                  id="paint0_radial_282_230"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(400.002 575.832) rotate(-90.6058) scale(537.698)"
                >
                  <stop offset="0.536458" stopColor="#330EC0" />
                  <stop offset="1" stopColor="#D4C9FF" />
                </radialGradient>
                <radialGradient
                  id="paint1_radial_282_230"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(391.307 534.469) rotate(-90.6058) scale(437.575)"
                >
                  <stop offset="0.536458" stopColor="#330EC0" />
                  <stop offset="1" stopColor="#D4C9FF" />
                </radialGradient>
                <radialGradient
                  id="paint2_radial_282_230"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(378.424 473.191) rotate(-90.6058) scale(289.245)"
                >
                  <stop offset="0.536458" stopColor="#330EC0" />
                  <stop offset="1" stopColor="#D4C9FF" />
                </radialGradient>
                <radialGradient
                  id="paint3_radial_282_230"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(642.963 365.536) rotate(-90.6058) scale(24.7218)"
                >
                  <stop offset="0.536458" stopColor="#330EC0" />
                  <stop offset="1" stopColor="#D4C9FF" />
                </radialGradient>
                <radialGradient
                  id="paint4_radial_282_230"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(132.097 185.23) rotate(-90.6058) scale(24.7218)"
                >
                  <stop offset="0.536458" stopColor="#330EC0" />
                  <stop offset="1" stopColor="#D4C9FF" />
                </radialGradient>
                <radialGradient
                  id="paint5_radial_282_230"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(169.304 402.361) rotate(-90.6058) scale(13.597)"
                >
                  <stop offset="0.536458" stopColor="#330EC0" />
                  <stop offset="1" stopColor="#D4C9FF" />
                </radialGradient>
                <radialGradient
                  id="paint6_radial_282_230"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(536.413 302.462) rotate(-90.6058) scale(13.597)"
                >
                  <stop offset="0.536458" stopColor="#330EC0" />
                  <stop offset="1" stopColor="#D4C9FF" />
                </radialGradient>
                <radialGradient
                  id="paint7_radial_282_230"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(400.002 575.435) rotate(-90.6058) scale(537.698)"
                >
                  <stop
                    offset="0.536458"
                    stopColor={getRings(active).stopColor1}
                  />
                  <stop offset="1" stopColor={getRings(active).stopColor2} />
                </radialGradient>
                <radialGradient
                  id="paint8_radial_282_230"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(391.307 534.072) rotate(-90.6058) scale(437.575)"
                >
                  <stop
                    offset="0.536458"
                    stopColor={getRings(active).stopColor1}
                  />
                  <stop offset="1" stopColor={getRings(active).stopColor2} />
                </radialGradient>
                <radialGradient
                  id="paint9_radial_282_230"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(378.424 472.794) rotate(-90.6058) scale(289.245)"
                >
                  <stop
                    offset="0.536458"
                    stopColor={getRings(active).stopColor1}
                  />
                  <stop offset="1" stopColor={getRings(active).stopColor2} />
                </radialGradient>
              </defs>
            </StaticSvg>
          </>
        )}
        {width <= 1079 && (
          <Image imageName={getMobileSVG(active)} maxWidth={"447px"} />
        )}
      </SvgWrapper>
    </>
  );
}

export default SvgRing;
