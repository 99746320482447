import React from "react";
import PropTypes from "prop-types";

import { FlexDiv } from "@/components/Atoms/Box";
import Button from "@/components/Atoms/Button";

import { noop, propTypeChildren } from "@/utils";

import theme from "@/foundation/Theme";

export function SubmitButton({
  children,
  buttonPadding,
  onClick,
  disabled,
  id,
  isLoading,
  variant,
  fontFamily,
  fontSize,
  version,
  width,
  minWidth,
  minHeight,
  boxShadow,
  ...rest
}) {
  return (
    <FlexDiv
      justifyContent="flex-start"
      alignItems="flex-end"
      flex="1"
      {...rest}
    >
      <Button
        bgColor={theme.colorsV2.shyft.purple_text}
        hoverColor={theme.colorsV2.shyft.purple_text}
        variant={variant}
        fontFamily={fontFamily}
        buttonPadding={buttonPadding}
        onClick={onClick}
        disabled={disabled}
        id={id}
        fontSize={fontSize}
        version={version}
        isLoading={isLoading}
        minWidth={minWidth}
        minHeight={minHeight}
        width={width}
        boxShadow={boxShadow}
      >
        {children}
      </Button>
    </FlexDiv>
  );
}

SubmitButton.propTypes = {
  children: propTypeChildren?.isRequired,
  buttonPadding: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  variant: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Array),
  ]),
  version: PropTypes.string,
  width: PropTypes.string,
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
  boxShadow: PropTypes.string,
};

SubmitButton.defaultProps = {
  onClick: noop,
  disabled: false,
  id: "",
  isLoading: false,
  width: "",
  variant: "primary1",
  fontFamily: "var(--man-700)",
  fontSize: ["14px", "15px", "16px"],
  version: "v2",
  boxShadow: `0px 10px 20px rgba(${theme.colors.shadows.purple_3}, 0.18), 0px 2px 6px rgba(${theme.colors.shadows.purple_3}, 0.12), 0px 0px 1px rgba(${theme.colors.shadows.black}, 0.04)`,
  minWidth: "123px",
  minHeight: "56px",
};
