import { AnimatePresence } from "framer-motion";
import React, { useState, useEffect } from "react";
import theme from "../../../foundation/Theme";
import Image from "next/image";
import {
  Container,
  Wrapper,
  Row,
  LHSSection,
  Badge,
  Title,
  RHSSection,
  ImageBox,
  ImageHolder,
  RHSImageBox,
  InnerBox,
  TextBox,
  CommentBox,
  Quotes,
  Comment,
  Name,
  TextWrap,
  DotsRow,
  Svg,
  Circle,
  MobileWrap,
} from "./style";

function CustomerStories({ data = {} }) {
  const { storyData, badge, title } = data;
  const [activeSlide, setActiveSlide] = useState(0);
  const [isChanging, setIsChanging] = useState(true);
  const slideDuration = 300;
  let progressEndValue = 11;
  const animateTime = `${progressEndValue + 1}s`;

  useEffect(() => {
    setIsChanging(false);
    let speed = 1000;
    let count = 0;

    let progress = setInterval(() => {
      count += 1;
      if (count > progressEndValue) {
        clearInterval(progress);
        if (activeSlide < storyData?.length - 1) {
          setActiveSlide(activeSlide + 1);
        } else if (activeSlide >= storyData?.length - 1) {
          setActiveSlide(0);
        }
      }
    }, speed);
    setTimeout(() => {
      setIsChanging(true);
    }, slideDuration);

    return () => clearInterval(progress);
  }, [activeSlide]);

  return (
    <Wrapper>
      <Container>
        <Row>
          <LHSSection>
            {badge && <Badge variant="label1">{badge}</Badge>}
            {title && (
              <Title isExtraBold variant="display4">
                {title}
              </Title>
            )}
            {storyData?.length > 0 && (
              <DotsRow>
                {storyData?.map((image, index) => (
                  <ImageBox
                    key={index}
                    isActiveSlide={activeSlide === index}
                    onClick={
                      activeSlide !== index
                        ? () => {
                            setActiveSlide(index);
                            setIsChanging(false);
                            setTimeout(() => {
                              setIsChanging(true);
                            }, slideDuration);
                          }
                        : () => {}
                    }
                  >
                    {image.logo && (
                      <Image
                        src={image.logo}
                        fill
                        style={{ objectFit: "cover" }}
                        alt={image?.text || "customer-stories"}
                      />
                    )}
                    <Svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                      fill="none"
                      isActiveSlide={activeSlide === index}
                      animateTime={animateTime}
                    >
                      <Circle cx="22" cy="22" r="21" />
                    </Svg>
                  </ImageBox>
                ))}
              </DotsRow>
            )}
          </LHSSection>
          <RHSSection>
            <MobileWrap>
              <InnerBox>
                <AnimatePresence>
                  {isChanging && (
                    <RHSImageBox
                      initial={{ x: 1000, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: 1000, opacity: 0 }}
                      transition={{
                        ease: "easeOut",
                        duration: 0.3,
                      }}
                    >
                      {storyData?.[activeSlide]?.image && (
                        <Image
                          src={storyData?.[activeSlide]?.image}
                          fill
                          style={{ objectFit: "cover" }}
                          alt={
                            storyData?.[activeSlide]?.text || "customer-stories"
                          }
                        />
                      )}
                    </RHSImageBox>
                  )}
                </AnimatePresence>
              </InnerBox>
              <InnerBox isAbsolute>
                <AnimatePresence>
                  {isChanging && (
                    <TextWrap
                      initial={{ x: 1000, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: 1000, opacity: 0 }}
                      transition={{
                        ease: "easeOut",
                        duration: 0.4,
                      }}
                    >
                      <TextBox>
                        <CommentBox>
                          <Quotes
                            icon={"icon-quotes"}
                            height="8px"
                            width="13px"
                          />
                          <Quotes
                            icon={"icon-quotes"}
                            height="8px"
                            width="13px"
                            invert={"true"}
                          />
                          <Comment variant="paragraph3">
                            {storyData[activeSlide]?.sub_title}
                          </Comment>
                        </CommentBox>
                        <Name variant="label3">
                          {storyData[activeSlide]?.title}
                        </Name>
                      </TextBox>
                    </TextWrap>
                  )}
                </AnimatePresence>
              </InnerBox>
            </MobileWrap>
          </RHSSection>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default CustomerStories;
