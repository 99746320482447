import styled from "styled-components";
import NextImage from "next/image";

import { Box } from "@/components/Atoms/Box";

export const ImageBox = styled(Box)`
  margin-bottom: ${({ imageOffSetBottom }) => imageOffSetBottom};
  z-index: 1;
  max-width: 100%;
  height: ${({ height }) => height};
  position: relative;
  align-self: center;
`;

export const AppCard = styled(Box)`
  padding: 32px 40px;
  border-radius: 24px;
  background: ${({ dark, isappdownload, theme }) =>
    !isappdownload
      ? dark
        ? theme.colorsV2.primary.shade_800
        : theme.colors.white
      : theme.colorsV2.blue.shade_600};
  max-width: ${(isappdownload) => (isappdownload ? "100%" : "500px")};
  z-index: 2;
  .title {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.white : theme.colorsV2.shyft.purple_text};
    font-weight: bold;
    padding: 0 0 8px;
  }
  .subtitle {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.white : theme.colorsV2.shyft.purple};
    padding: ${({ isappdownload }) => (isappdownload ? "8px 0" : "8px 0 16px")};
  }
  @media only screen and (max-width: 767px) {
    max-width: 100%;
    padding: 32px;
  }
`;

export const FooterPlayIcon = styled(NextImage)`
  max-width: 130px;
  @media only screen and (max-width: 420px) {
    max-width: 120px;
  }
  @media only screen and (max-width: 390px) {
    max-width: 100px;
  }
  ${({ isappdownload }) =>
    isappdownload &&
    ` 
    @media only screen and (max-width: 359px) {
      max-width: 72px;
    }
    `};
`;
