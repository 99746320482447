import React from "react";
import PropTypes from "prop-types";

import { FlexDiv } from "@/components/Atoms/Box";

import { HeadingWrapper, Title } from "./style";

function SectionHeading({
  title,
  mb,
  color,
  variant,
  mobilePadding,
  isTestimonial,
  disableSeparator,
}) {
  return (
    <HeadingWrapper mobilePadding={mobilePadding} isTestimonial={isTestimonial}>
      <FlexDiv flexDirection={"column"} mb={mb}>
        <Title
          my={2}
          color={color}
          variant={variant}
          themeColor={color}
          disableSeparator={disableSeparator}
        >
          {title}
        </Title>
      </FlexDiv>
    </HeadingWrapper>
  );
}

SectionHeading.propTypes = {
  title: PropTypes.string,
  mb: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  mobilePadding: PropTypes.string,
  isTestimonial: PropTypes.bool,
  disableSeparator: PropTypes.bool,
};

SectionHeading.defaultProps = {
  title: "",
  mb: "",
  color: "",
  variant: "heading4",
  mobilePadding: "8px",
  isTestimonial: false,
  disableSeparator: false,
};

export default SectionHeading;
