import React from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import Image from "next/image";

import theme from "../../../../foundation/Theme";
import SectionHeading from "../../SectionHeading";
import Typography from "../../../../foundation/Typography/V2";

import {
  Container,
  Wrapper,
  SliderWrapper,
  CardWrapper,
  ImageHolder,
  ImageComponent,
  BodyComponent,
  Title,
  TagsWrapper,
  TagBox,
  About,
  CTABox,
  CTABtn,
  CtaLink,
  Icon,
} from "./sliderStyle";

function HomeSlider({
  data,
  arrowImage,
  disableSeparator,
  isTestimonial,
  headingColor,
}) {
  const { title, components } = data;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2.5,
          infinite: false,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.5,
          infinite: false,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 1.25,
          infinite: false,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  const getTagUrl = (tags, data) => {
    switch (tags) {
      case "Yoga": {
        if (data?.title === "PCOS")
          return "https://www.betheshyft.com/yoga-for-pcos/";
        if (data?.title === "Prenatal")
          return "https://www.betheshyft.com/prenatal-yoga-and-breathwork/";
        if (data?.title === "Postnatal")
          return "https://www.betheshyft.com/postnatal-yoga-and-breathwork/";
        if (data?.title === "Wellness")
          return "https://www.betheshyft.com/yoga-for-wellness/";
        if (data?.title === "Hypertension")
          return "https://www.betheshyft.com/yoga-for-hypertension/";
        if (data?.title === "Thyroid")
          return "https://www.betheshyft.com/yoga-for-thyroid/";
        break;
      }
      case "Nutrition":
        return "https://www.betheshyft.com/nutrition/";
      default:
        return "";
    }
  };

  return (
    <Wrapper>
      <Container>
        {title && (
          <SectionHeading
            title={title}
            disableSeparator={disableSeparator}
            isTestimonial={isTestimonial}
            color={headingColor}
          />
        )}
        <SliderWrapper
          maxWidth={"100%"}
          arrowImage={arrowImage}
          dotsColor={headingColor}
        >
          <Slider {...settings}>
            {components.map((data, index) => {
              return (
                <CardWrapper bg={theme.colors.white} key={index}>
                  {data.image &&
                    (data.url ? (
                      <Typography
                        variant="link2"
                        fontSize={["18px", "21px", "24px"]}
                        lineHeight={["24px", "28px", "32px"]}
                        target="_blank"
                        href={
                          data.url?.endsWith("/") ? data.url : `${data.url}/`
                        }
                      >
                        <ImageHolder>
                          <Image
                            src={data.image}
                            alt={data?.image_alt_text}
                            fill
                            style={{ objectFit: "cover" }}
                          />
                        </ImageHolder>
                      </Typography>
                    ) : (
                      <ImageHolder>
                        <Image
                          src={data.image}
                          alt={data?.image_alt_text}
                          fill
                          style={{ objectFit: "cover" }}
                        />
                      </ImageHolder>
                    ))}
                  <BodyComponent>
                    {data.title && (
                      <Title
                        variant={data.url ? "link2" : "heading5"}
                        fontSize={["18px", "21px", "24px"]}
                        lineHeight={["24px", "28px", "32px"]}
                        target="_blank"
                        href={
                          data.url?.endsWith("/") ? data.url : `${data.url}/`
                        }
                      >
                        {data.title}
                      </Title>
                    )}
                    {data?.tag?.length > 0 && (
                      <TagsWrapper>
                        {data?.tag?.map((tags, k) => {
                          const url = getTagUrl(tags, data);
                          return (
                            <TagBox
                              key={k}
                              variant={url ? "link2" : "label4"}
                              fontSize={["10px", "10px", "12px"]}
                              lineHeight={["16px", "16px", "16px"]}
                              target="_blank"
                              href={url?.endsWith("/") ? url : `${url}/`}
                            >
                              {tags}
                            </TagBox>
                          );
                        })}
                      </TagsWrapper>
                    )}
                    {data?.sub_title && (
                      <About variant="paragraph4">{data.sub_title}</About>
                    )}
                    {/* {data?.description && data?.url && (
                      <CTABox>
                        <CtaLink variant="routelink1" to={data?.url}>
                          <CTABtn variant="label4">
                            {data?.description}
                            <Icon icon="icon-arrow-right-purple" />
                          </CTABtn>
                        </CtaLink>
                      </CTABox>
                    )} */}
                  </BodyComponent>
                </CardWrapper>
              );
            })}
          </Slider>
        </SliderWrapper>
      </Container>
    </Wrapper>
  );
}

HomeSlider.propTypes = {
  data: PropTypes.instanceOf(Object),
  arrowImage: PropTypes.string,
  headingColor: PropTypes.string,
  disableSeparator: PropTypes.bool,
  isTestimonial: PropTypes.bool,
};
HomeSlider.defaultProps = {
  arrowImage: "arrow-solid.svg",
  data: {},
  disableSeparator: true,
  isTestimonial: true,
  headingColor: theme.colorsV2.shyft.purple_text,
};

export default HomeSlider;
