import React from "react";

import { ServiceWrapper } from "@/components/Molecules/Services";
import { SliderWrapperContent } from "@/components/Molecules/Services/ServiceSlider";

import { useAppSelector } from "@/hooks";

import theme from "@/foundation/Theme";

import {
  Wrapper,
  Title,
  Subtitle,
  PrimaryCTA,
  ButtonDiv,
  SecondaryCTA,
  FeaturesDiv,
  FeatureItem,
  FeatureTitle,
  FeatureSubtitle,
  Stars,
  ArrowRight,
  Span,
  OverrideHeaderStyle,
  TopContainer,
  InnerTop,
  DesktopServiceWrapper,
  MobileServiceWrapper,
  Description,
} from "./styles";

function HeroSection({ data, width, setShowModal }) {
  const {
    title,
    subtitle,
    primaryCTA,
    secondaryCTA,
    features,
    services,
    description,
  } = data || {};

  const { headerHeight } = useAppSelector((state) => state.dashboard);

  return (
    <Wrapper id="hero-section" topDistance={headerHeight}>
      <TopContainer>
        <InnerTop>
          <h1 className="seo-heading-1">
            Online Yoga in interactive video classes
          </h1>
          <Title
            variant="display2"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Description
            variant="display4"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <Subtitle
            variant="display4"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
          <ButtonDiv>
            <PrimaryCTA
              variant="primary1"
              fontSize={["14px", "15px", "16px"]}
              fontFamily={"var(--man-700)"}
              bgColor={theme.colors.white}
              hoverColor={theme.colors.white}
              onClick={() => (window.location.href = "health/")}
              TextColor={theme.colorsV2.shyft.purple_text}
            >
              {primaryCTA}
            </PrimaryCTA>
            <SecondaryCTA
              variant="primary1"
              fontSize={["14px", "15px", "16px"]}
              fontFamily={"var(--man-700)"}
              bgColor={theme.colorsV2.primary.shade_900}
              hoverColor={theme.colorsV2.primary.shade_900}
              onClick={() => {
                setShowModal(true);
              }}
              TextColor={theme.colorsV2.primary.shade_25}
            >
              {secondaryCTA}
              <Span variant="span">
                <ArrowRight icon="icon-arrow-right" />
              </Span>
            </SecondaryCTA>
          </ButtonDiv>
          {features?.length > 0 && (
            <FeaturesDiv>
              {features?.map((item, index) => {
                if (width <= 1023 && index >= 1) {
                  return (
                    <FeatureItem key={index}>
                      <Stars icon="icon-sparkling-stars" />
                      {item.title && (
                        <FeatureTitle
                          variant="heading5"
                          fontFamily={"var(--sora-700)"}
                        >
                          {item.title}
                        </FeatureTitle>
                      )}
                      {item.sub_title && (
                        <FeatureSubtitle variant="label3">
                          {item.sub_title}
                        </FeatureSubtitle>
                      )}
                    </FeatureItem>
                  );
                } else if (width > 767) {
                  return (
                    <FeatureItem key={index}>
                      <Stars icon="icon-sparkling-stars" />
                      {item.title && (
                        <FeatureTitle
                          variant="heading5"
                          fontFamily={"var(--sora-700)"}
                        >
                          {item.title}
                        </FeatureTitle>
                      )}
                      {item.sub_title && (
                        <FeatureSubtitle variant="label3">
                          {item.sub_title}
                        </FeatureSubtitle>
                      )}
                    </FeatureItem>
                  );
                }
              })}
            </FeaturesDiv>
          )}
        </InnerTop>
        {services?.components?.length > 0 && (
          <>
            <DesktopServiceWrapper>
              <ServiceWrapper data={data} width={width} setBackground />
            </DesktopServiceWrapper>
            <MobileServiceWrapper>
              <SliderWrapperContent data={data} />
            </MobileServiceWrapper>
          </>
        )}
      </TopContainer>
      <OverrideHeaderStyle />
    </Wrapper>
  );
}

export default HeroSection;
