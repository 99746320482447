import React from "react";

import { FlexDiv } from "@/components/Atoms/Box";
import {
  TextInput,
  PhoneInput,
  InputError,
  SubmitButton,
  Dropdown,
  TagGroup,
} from "@/components/Organisms/FormInputs";
import ToggleInput from "@/components/Atoms/InputsV2/ToggleInput";
import { SubmitButtonV2 } from "@/components/Organisms/FormInputs/ActionButtons/V2";

import theme from "@/foundation/Theme";

import { BodyWrap, FormBox } from "./style";

const RegisterForm = (props) => {
  const {
    isLoading = false,
    showLabels = true,
    id,
    error,
    onSubmitButtonClick,
    marginBottom,
    handleBlur = () => {},
    handleChange = () => {},
    handleSelectChange = () => {},
    showGoalsField,
    goalPlaceholder,
    goalOptions,
    goal,
    onGoalChange,
    goalError,
    showServicesField,
    serviceList,
    serviceLabel,
    selectedServiceIndex,
    onServiceChange,
    serviceError,
    name,
    nameError,
    namePlaceholder,
    phonePlaceholder,
    mobile,
    countryCode,
    mobileError,
    showEmailField,
    email,
    emailError,
    emailPlaceholder,
    buttonText,
    buttonPadding,
    showRequestWebinarField,
    requestWebinarPlaceholder,
    requestWebinar,
    requestWebinarError,
    variant = "v2",
    nameIcon = "icon-user-face-2",
    inputBorderRadius = "10px",
    focusable = false,
    homePageButton = false,
    goalLabel = "Health Condition",
    isAllInputsFilled,
    dropdownDisable = false,
    swapGoalService,
    showJoinCommunityToggle,
    joinCommunity,
    setJoinCommunity = () => {},
  } = props || {};

  const Goals = showGoalsField && (
    <Dropdown
      name="goal"
      mb={marginBottom}
      placeholder={goalPlaceholder}
      options={goalOptions}
      value={goal}
      onChange={onGoalChange}
      error={goalError}
      variant={variant}
      goalLabel={goalLabel}
      isDisabled={dropdownDisable}
    />
  );

  const Services = showServicesField && (
    <TagGroup
      tagList={serviceList}
      label={serviceLabel}
      selectedIndex={selectedServiceIndex}
      onClick={onServiceChange}
      error={serviceError}
      color={theme.colorsV2.gray.shade_500}
      activeColor={theme.colorsV2.shyft.purple_text}
      labelColor={theme.colorsV2.gray.shade_400}
      mb={marginBottom}
    />
  );

  return (
    <BodyWrap className="callback-form-containers" width="100%" flex={1}>
      <FlexDiv flexDirection="column" width="100%">
        {error && (
          <FlexDiv mb="8px">
            <InputError isNotAbsolute error={error} />
          </FlexDiv>
        )}
        <FormBox onSubmit={(e) => e.preventDefault()}>
          {swapGoalService ? (
            <>
              {Services}
              {Goals}
            </>
          ) : (
            <>
              {Goals}
              {Services}
            </>
          )}

          <TextInput
            mb={marginBottom}
            showLabels={showLabels}
            reference={props.forwardedRef}
            name="name"
            icon={nameIcon}
            placeholder={namePlaceholder}
            value={name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={nameError}
            required
            variant={variant}
            inputBorderRadius={inputBorderRadius}
            focusable={focusable}
          />
          <PhoneInput
            mb={marginBottom}
            showLabels={showLabels}
            reference={props.forwardedRef}
            placeholder={phonePlaceholder}
            value={mobile}
            onChange={handleChange}
            handleSelectChange={handleSelectChange}
            onBlur={handleBlur}
            error={mobileError}
            countryCode={countryCode}
            required
            variant={variant}
            focusable={focusable}
            inputBorderRadius={inputBorderRadius}
          />
          {showEmailField && (
            <TextInput
              mb={marginBottom}
              showLabels={showLabels}
              reference={props.forwardedRef}
              placeholder={emailPlaceholder}
              name="email"
              type="email"
              icon="icon-EnvelopeSimple"
              value={email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={emailError}
              required
              inputBorderRadius={inputBorderRadius}
              variant={variant}
            />
          )}
          {showRequestWebinarField && (
            <TextInput
              mb={marginBottom}
              showLabels={showLabels}
              reference={props.forwardedRef}
              name="requestWebinar"
              icon="icon-chat-shyft"
              placeholder={requestWebinarPlaceholder}
              value={requestWebinar}
              onChange={handleChange}
              onBlur={handleBlur}
              error={requestWebinarError}
              required
              variant={variant}
              inputBorderRadius={inputBorderRadius}
              focusable={focusable}
            />
          )}
          {showJoinCommunityToggle && (
            <ToggleInput
              name="joinCommunity"
              value={joinCommunity}
              onChange={setJoinCommunity}
              onBlur={handleBlur}
              className="joinCommunityToggle"
              label="Add me to Shyft's Whatsapp community for health resources + inspiration."
            />
          )}
          {homePageButton ? (
            <SubmitButtonV2
              mt={"16px"}
              buttonPadding={buttonPadding}
              onClick={onSubmitButtonClick}
              disabled={isLoading || !isAllInputsFilled()}
              id={id}
              isLoading={isLoading}
            >
              {buttonText}
            </SubmitButtonV2>
          ) : (
            <SubmitButton
              mt={marginBottom}
              buttonPadding={buttonPadding}
              onClick={onSubmitButtonClick}
              disabled={isLoading}
              id={id}
              isLoading={isLoading}
            >
              {buttonText}
            </SubmitButton>
          )}
        </FormBox>
      </FlexDiv>
    </BodyWrap>
  );
};

export default RegisterForm;
