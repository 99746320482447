import styled from "styled-components";
import { space } from "styled-system";

import { Box, FlexContainer, FlexDiv } from "@/components/Atoms/Box";

import Typography from "@/foundation/Typography/V2";

export const FooterBox = styled.footer`
  ${space}
  background: ${({ dark, theme }) =>
    dark ? theme.colorsV2.shyft.purple_text : theme.colorsV2.shyft.cream};
  padding: 80px 0 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 767px) {
    padding: 24px 8px 16px;
  }
`;
export const BrandBox = styled(FlexDiv)`
  padding: 24px;
  flex-direction: column;
  @media only screen and (max-width: 1139px) {
    padding: 16px 0;
  }
`;
export const FooterContainer = styled(FlexContainer)`
  @media only screen and (max-width: 1139px) {
    padding: 0;
  }
`;
export const FooterTop = styled(FlexDiv)`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1139px) {
    flex-direction: column;
  }
`;
export const FooterSection = styled(FlexDiv)`
  flex-direction: column;
  width: 100%;

  &:first-child {
    padding: 0 36px 0 0;
    flex: 1;
    max-width: 644px;
    @media only screen and (max-width: 1139px) {
      padding: 0 16px;
      max-width: 100%;
    }
  }
  &:last-child {
    max-width: 564px;
    margin: 0 auto;
    padding: 0 0 0 0px;
    @media only screen and (max-width: 1139px) {
      padding: 24px;
      margin: 16px auto 0;
    }
    @media only screen and (max-width: 767px) {
      padding: 0 8px;
    }
  }
`;

export const Terms = styled(Box)`
  padding: 48px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  .term {
    position: relative;
    padding: 0px 32px 0 0;
    display: flex;
    align-items: center;
    &::after {
      content: "";
      border-right: 1px solid
        ${({ dark, theme }) =>
          dark ? theme.colors.white : theme.colorsV2.primary.shade_800};
      padding: 0 16px;
      height: calc(100% - 8px);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
    }
    &:last-child {
      padding-right: 0;
      &:after {
        content: normal;
      }
    }
    @media only screen and (max-width: 1139px) {
      margin-bottom: 8px;
    }
  }
  @media only screen and (max-width: 1139px) {
    padding: 48px 16px 0;
  }
`;

export const StyledLink = styled(Typography)`
  &:hover {
    text-decoration: underline;
  }
`;
