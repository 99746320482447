import React from "react";
import NextImage from "next/image";

import { Box, ALink, FlexDiv } from "@/components/Atoms/Box";
import footerData from "../FooterData";

import Typography from "@/foundation/Typography/V2";

import { FooterSection } from "../style";
import { ImageBox, AppCard, FooterPlayIcon } from "./style";

function RightSection({
  dark,
  isAppDownload = false,
  imageSrc = footerData?.appCard?.image,
  imageOffSetBottom = "-16px",
  rightSubtitle = footerData?.appCard?.subtitle,
  rightTitle = footerData?.appCard?.title,
  imageHeight = "184px",
  imageImageWidth = "275px",
  isCTAFormView = false,
}) {
  return (
    <FooterSection>
      <ImageBox
        imageOffSetBottom={imageOffSetBottom}
        height={imageHeight}
        width={imageImageWidth}
      >
        <NextImage
          src={imageSrc}
          fill={true}
          style={{ objectFit: "cover" }}
          alt="footer"
        />
      </ImageBox>
      <AppCard
        dark={dark}
        isappdownload={isAppDownload ? 1 : undefined}
        isCTAFormView={isCTAFormView}
      >
        <Typography
          variant="heading6"
          className="title"
          dark={dark}
          textAlign={isCTAFormView && "center"}
          isExtraBold={true}
        >
          {rightTitle}
        </Typography>
        <Typography
          variant="paragraph3"
          className="subtitle"
          isExtraBold={true}
          dark={dark}
          textAlign={isCTAFormView && "center"}
        >
          {rightSubtitle}
        </Typography>
        <FlexDiv justifyContent={isCTAFormView && "space-around"}>
          {footerData?.appCard?.appLinks?.map((link) => (
            <Box key={link.text} className={!isCTAFormView && "mr-3"}>
              <ALink href={link.link} target="_blank">
                <FooterPlayIcon
                  className="img-fluid lazyload loaded"
                  src={dark ? link.iconDark : link.icon}
                  isappdownload={isAppDownload ? 1 : undefined}
                  height={40}
                  width={130}
                  alt="playstore"
                />
              </ALink>
            </Box>
          ))}
        </FlexDiv>
      </AppCard>
    </FooterSection>
  );
}

export default RightSection;
