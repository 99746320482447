import styled, { createGlobalStyle } from "styled-components";

import { FlexDiv } from "@/components/Atoms/Box";

import Typography from "@/foundation/Typography/V2";
import theme from "@/foundation/Theme";

export const GlobalCss = createGlobalStyle`
body {overflow-x: visible;}
.main-wrapper{
    min-height:100vh;
}
`;

export const BannerImage = styled(FlexDiv)`
  background-color: ${theme.colors.white};
  background-image: ${theme.colors.linear_gradient};
  padding-bottom: 50px;
  box-shadow: 0 13px 18px rgba(${theme.colors.shadows.banner}, 0.04);
  @media only screen and (max-width: 1129px) {
    box-shadow: none;
  }
`;

export const Wrapper = styled(FlexDiv)``;

export const HowItWorks = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.gray.shade_700};
  @media only screen and (min-width: 1080px) {
    border-radius: 76px;
    margin: 76px 0;
  }
`;

export const FloatingBtn = styled(FlexDiv)`
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  margin: 0 auto;
`;

export const RingWrapper = styled(FlexDiv)`
  @media only screen and (max-height: 650px) {
    max-width: 80%;
  }
`;
