import styled from "styled-components";

import { FlexDiv } from "@/components/Atoms/Box";

import Typography from "@/foundation/Typography/V2";

export const InputGroup = styled(FlexDiv)`
  flex-direction: column;
  position: relative;
  margin-bottom: ${({ hasBottomBorder, inputMarginBottom }) =>
    inputMarginBottom || (hasBottomBorder ? "41px" : "33px")};
  @media only screen and (max-width: 767px) {
    margin-bottom: ${({ hasBottomBorder, hasNoError }) =>
      hasBottomBorder ? "33px" : hasNoError ? "16px" : "24px"};
  }
`;
export const Sup = styled.sup``;
export const NameLabel = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.gray.shade_700};
  margin-bottom: 8px;
  ${Sup} {
    font-family: inherit;
    font-size: inherit;
    color: ${({ theme }) => theme.colorsV2.shyft.error};
    top: 0;
  }
`;
export const PhoneLabel = styled(NameLabel)``;
