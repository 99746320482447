import React from "react";
import PropTypes from "prop-types";

import { FlexDiv } from "@/components/Atoms/Box";
import Input from "@/components/Atoms/Inputs/Input";
import InputError from "@/components/Organisms/FormInputs/InputError";

import { noop, propTypeRef } from "@/utils";

import { InputGroup, NameLabel, Sup } from "../style.js";

function TextInput({
  showLabels,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  required,
  icon,
  iconDimension,
  variant,
  error,
  mb,
  reference,
  type,
  inputBorderRadius,
  focusable,
  nameLabel,
  inputMarginBottom,
  inputWidth,
  isNotAbsolute,
  ...rest
}) {
  return (
    <FlexDiv flexDirection={"column"} mb={mb} width={inputWidth}>
      {nameLabel && (
        <NameLabel variant="label3">
          {nameLabel}
          {required && <Sup>*</Sup>}
        </NameLabel>
      )}
      <InputGroup
        hasBottomBorder
        showLabels={showLabels}
        inputMarginBottom={inputMarginBottom}
      >
        <Input
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete="off"
          ref={reference}
          required={required}
          icon={icon}
          iconDimension={iconDimension}
          variant={variant}
          inputBorderRadius={inputBorderRadius}
          focusable={focusable}
          error={error && variant === "v3" ? error : ""}
          isNotAbsolute={isNotAbsolute}
          {...rest}
        />
        {error && variant !== "v3" && (
          <InputError
            hasBottomBorder
            error={error}
            isNotAbsolute={isNotAbsolute}
          />
        )}
      </InputGroup>
    </FlexDiv>
  );
}

TextInput.propTypes = {
  showLabels: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  icon: PropTypes.string,
  iconDimension: PropTypes.string,
  variant: PropTypes.string,
  error: PropTypes.string,
  mb: PropTypes.string,
  reference: propTypeRef,
  type: PropTypes.string,
  inputBorderRadius: PropTypes.string,
  focusable: PropTypes.bool,
};

TextInput.defaultProps = {
  showLabels: false,
  placeholder: "",
  name: "",
  value: "",
  onChange: noop,
  onBlur: noop,
  required: false,
  icon: "",
  iconDimension: "16px",
  variant: "v2",
  error: "",
  mb: "",
  reference: noop,
  type: "text",
  inputBorderRadius: "10px",
  focusable: false,
};

export default TextInput;
