import { motion } from "framer-motion";
import styled from "styled-components";

import { FlexSection, FlexDiv, FlexContainer } from "@/components/Atoms/Box";
import Image from "@/components/Atoms/Image";
import SVGSprite from "@/components/Atoms/SVGSprite";
import { getCustomScroll } from "@/components/Atoms/Scrollbar";

import Typography from "@/foundation/Typography/V2";

export const Wrapper = styled(FlexSection)`
  padding: 60px 0 80px;
  background-color: ${({ theme }) => theme.colorsV2.gray.shade_50};
  @media only screen and (max-width: 1169px) {
    padding: 24px 0;
  }
`;
export const Container = styled(FlexContainer)`
  padding: 0 40px;
  flex-direction: column;
`;

export const Row = styled(FlexDiv)`
  align-items: center;
  @media only screen and (max-width: 1169px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;
export const DotsRow = styled(FlexDiv)`
  align-items: center;
  @media only screen and (max-width: 1169px) {
    justify-content: center;
  }
`;
export const LHSSection = styled(FlexDiv)`
  flex-direction: column;
  padding: 36px 16px;
  width: 100%;
  max-width: 464px;
  margin-right: 16px;
  @media only screen and (max-width: 1169px) {
    margin-right: 0;
    max-width: 100%;
    padding: 0 16px 24px;
  }
`;
export const RHSSection = styled(FlexDiv)`
  flex: 1;
  padding: 0 16px;
  flex-direction: column;
  height: 100%;
  @media only screen and (max-width: 1169px) {
    width: 100%;
  }
`;
export const InnerBox = styled(FlexDiv)`
  user-select: none;
  position: ${({ isAbsolute }) => (isAbsolute ? "absolute" : "relative")};
  width: 100%;
  ${({ isAbsolute }) =>
    isAbsolute &&
    `
  top:0;
  left:0;
  height: 100%;
  z-index:1;
  @media only screen and (max-width: 1169px){
    position: relative;
    left: unset;
    top: unset;
  }

  `};
  @media only screen and (max-width: 1169px) {
    justify-content: center;
  }
`;
export const Badge = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.shyft.purple_text};
  margin-bottom: 24px;
  @media only screen and (max-width: 1169px) {
    margin-bottom: 16px;
  }
`;
export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.purple.shade_900};
  margin-bottom: 24px;
  @media only screen and (max-width: 1169px) {
    margin-bottom: 16px;
  }
`;
export const ImageHolder = styled(Image)``;
export const ImageBox = styled(FlexDiv)`
  position: relative;
  user-select: none;
  cursor: ${({ isActiveSlide }) => (isActiveSlide ? "default" : "pointer")};
  height: 44px;
  width: 44px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  &:last-child {
    margin-right: 0;
  }
  padding: 2px;
  ${ImageHolder} {
    height: 100%;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 50%;
    position: relative;
    z-index: 1;
  }
`;
export const RHSImageBox = styled(motion.div)`
  height: 100%;
  max-height: 376px;
  width: 100%;
  max-width: 308px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  ${ImageHolder} {
    height: 100%;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  @media only screen and (max-width: 424px) {
    max-width: 250px;
    max-height: 350px;
  }
`;
export const TextWrap = styled(motion.div)`
  width: 100%;
`;
export const TextBox = styled(FlexDiv)`
  position: absolute;
  user-select: none;
  transition: all 0.3s ease-in-out;
  top: 50%;
  left: 284px;
  flex-direction: column;
  transform: translateY(-50%);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 48px 48px 32px;
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  max-width: calc(100% - 304px);
  max-height: 80%;
  box-shadow: 0px 0px 4px
      rgba(${({ theme }) => theme.colors.shadows.black}, 0.04),
    0px 4px 8px rgba(${({ theme }) => theme.colors.shadows.black}, 0.06);
  &:before {
    content: "";
    height: 100%;
    width: 8px;
    background-color: ${({ theme }) => theme.colorsV2.shyft.purple_text};
    position: absolute;
    left: 0;
    top: 0;
  }
  @media only screen and (max-width: 1169px) {
    position: relative;
    top: unset;
    left: unset;
    margin: 0 auto;
    transform: translateY(-8px);
    max-width: 420px;
    max-height 100%;
    padding: 48px 32px 32px;
  }
`;
export const CommentBox = styled(FlexDiv)`
  position: relative;
  margin-bottom: 8px;
`;
export const Comment = styled(Typography)`
  max-height: 168px;
  overflow: auto;
  color: ${({ theme }) => theme.colorsV2.gray.shade_700};
  ${getCustomScroll({})};
  transition: all 0.3s ease-in-out;
`;
export const Quotes = styled(SVGSprite)`
  position: absolute;
  fill: ${({ theme }) => theme.colorsV2.warning.shade_300};
  ${({ invert }) =>
    invert === "true"
      ? `
  bottom: -8px;
  right: 0px;
  transform: rotate(180deg);
  `
      : `
  top: -16px;
  left: 0px;
  `};
`;
export const Name = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.gray.shade_800};
`;
export const Circle = styled.circle`
  stroke: ${({ theme }) => theme.colorsV2.purple.shade_600};
  stroke-width: 2px;
  stroke-dashoffset: 131.95px;
  stroke-dasharray: 131.95px;
`;
export const Svg = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  height: 100%;
  width: 100%;
  ${({ isActiveSlide, animateTime }) =>
    isActiveSlide &&
    `
    ${Circle}{
      animation: rotateRing ${animateTime} linear forwards;
    }
  `}
  @keyframes rotateRing {
    from {
      stroke-dashoffset: 131.95px;
    }
    to {
      stroke-dashoffset: 0px;
    }
  }
`;
export const MobileWrap = styled(FlexDiv)`
  overflow: hidden;
  position: relative;
  height: 100%;
  @media only screen and (max-width: 1169px) {
    width: 100%;
    max-width: 100%;
    flex-direction: column;
  }
`;
