import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import NextImage from "next/image";

import { Box } from "@/components/Atoms/Box";

import Typography from "@/foundation/Typography/V2";

import {
  MainWrapper,
  SliderWrapper,
  Container,
  CardWrapper,
  SliderImageBox,
  SliderImageHolder,
  RHSBox,
  SliderLogoBox,
  LogoImage,
  Title,
  SubText,
} from "./styles";

function ServiceSlider({ data }) {
  return (
    <MainWrapper>
      <Container flexDirection={"column"}>
        <SliderWrapperContent data={data} />
      </Container>
    </MainWrapper>
  );
}
export const SliderWrapperContent = ({ data }) => {
  const { services } = data;
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2.5,
          infinite: false,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          infinite: false,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 1.2,
          infinite: false,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <SliderWrapper maxWidth={"100%"}>
      <Slider {...settings}>
        {services?.components?.map((item, index) => (
          <CardWrapper key={index}>
            {item.image && (
              <SliderImageBox>
                {item.url ? (
                  <Typography
                    variant="link2"
                    fontSize={["18px", "21px", "24px"]}
                    lineHeight={["24px", "28px", "32px"]}
                    target="_blank"
                    href={item.url?.endsWith("/") ? item.url : `${item.url}/`}
                    style={{ width: "100%" }}
                  >
                    <Box
                      style={{
                        position: "relative",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <NextImage
                        fill
                        src={item.image}
                        style={{ objectFit: "cover" }}
                      />
                    </Box>
                  </Typography>
                ) : (
                  <Box
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <NextImage
                      fill
                      src={item.image}
                      style={{ objectFit: "cover" }}
                      alt="hero-carousel"
                    />
                  </Box>
                )}
              </SliderImageBox>
            )}
            <RHSBox>
              {item.logo && (
                <SliderLogoBox>
                  <NextImage
                    fill
                    src={item.logo}
                    style={{ objectFit: "cover" }}
                  />
                </SliderLogoBox>
              )}
              <Title
                variant={item.url ? "link2" : "heading5"}
                fontSize={["18px", "21px", "24px"]}
                lineHeight={["24px", "28px", "32px"]}
                target="_blank"
                href={item.url?.endsWith("/") ? item.url : `${item.url}/`}
              >
                {item.title}
              </Title>
              <SubText
                fontFamily="var(--man-700)"
                variant="paragraph3"
                flex="1"
                letterSpacing="0"
                lineHeight={["20px", "20px", "20px"]}
              >
                {item.sub_title}
              </SubText>
            </RHSBox>
          </CardWrapper>
        ))}
      </Slider>
    </SliderWrapper>
  );
};

ServiceSlider.propTypes = {
  data: PropTypes.instanceOf(Object),
};
ServiceSlider.defaultProps = {
  data: {},
};

export default ServiceSlider;
