import React, { useState, useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "@/hooks";
import PropTypes from "prop-types";

import { FlexDiv } from "@/components/Atoms/Box";
import Image from "@/components/Atoms/Image";
import Input from "@/components/Atoms/Inputs/Input";
import CountryDropdown from "@/components/Atoms/Inputs/CountryDropdown";
import InputError from "../InputError";
import {
  countryFlagList,
  formatOptionLabelV2,
} from "@/components/Molecules/FormatOptionLabel/V2";

import { noop, propTypeRef } from "@/utils";

import Typography from "@/foundation/Typography/V2";
import theme from "@/foundation/Theme";

import * as dashboardActions from "@/redux/DashboardReducer";

import {
  ArrowDown,
  CountryPlaceholder,
  DropDown,
  InputWrap,
  NumberPlaceholder,
  PhoneFlag,
  PhoneIcon,
} from "./style.js";
import { InputGroup, PhoneLabel, Sup } from "../style.js";

function PhoneInput({
  showLabels,
  placeholder,
  value,
  onChange,
  onBlur,
  required,
  variant,
  error,
  reference,
  countryCode,
  handleSelectChange,
  inputBorderRadius,
  focusable,
  phoneLabel,
  inputPadding,
  inputMarginBottom,
  inputColor,
  ...rest
}) {
  const dispatch = useAppDispatch();

  const wrapperRef = useRef(null);

  const { countryCodeData: countryCodes } = useAppSelector(
    (state) => state.dashboard
  );

  const [showCountryDropDown, setShowCountryDropDown] = useState(false);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      // Alert if clicked on outside of element
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowCountryDropDown(false);
        }
      };
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(wrapperRef);

  const countryFlag = countryFlagList?.[countryCode];

  return (
    <FlexDiv flexDirection={"column"} {...rest}>
      {phoneLabel && (
        <PhoneLabel variant="label3">
          {phoneLabel}
          {required && <Sup>*</Sup>}
        </PhoneLabel>
      )}
      <InputGroup
        hasBottomBorder
        showLabels={showLabels}
        inputMarginBottom={inputMarginBottom}
      >
        <InputWrap>
          <CountryPlaceholder
            showCountryDropDown={showCountryDropDown}
            variant={variant}
          >
            <NumberPlaceholder
              onClick={() => setShowCountryDropDown(!showCountryDropDown)}
              position="relative"
            >
              {countryFlag ? (
                <PhoneFlag height={"16px"} width={"24px"}>
                  <Image
                    maxWidth={"24px"}
                    maxHeight={"16px"}
                    imageName={`flags/${countryFlag}`}
                    alt="flag"
                  />
                </PhoneFlag>
              ) : (
                <PhoneIcon
                  icon="icon-phone-2"
                  fill={theme.colorsV2.gray.shade_700}
                  height={"16px"}
                  width={"16px"}
                />
              )}
              <Typography
                variant={"label2"}
                color={inputColor || theme.colorsV2.gray.shade_700}
                width={"100%"}
                textAlign={"center"}
              >
                {countryCode}
              </Typography>
              <ArrowDown
                icon={"icon-caret-down"}
                fill={theme.colorsV2.gray.shade_700}
                height={"8px"}
                width={"8px"}
              />
            </NumberPlaceholder>
            <DropDown
              showCountryDropDown={showCountryDropDown}
              ref={wrapperRef}
            >
              <CountryDropdown
                menuIsOpen={showCountryDropDown}
                options={countryCodes?.list}
                formatOptionLabel={formatOptionLabelV2}
                wrapperClassName={"shyftList"}
                dropDownHeight={"180px"}
                placeholder={"Search"}
                defaultValue={countryCode}
                onChange={(countryCode) => {
                  handleSelectChange("countryCode", countryCode.code);
                  setShowCountryDropDown(false);
                }}
                closeMenuOnSelect
                variant={"v2"}
              />
            </DropDown>
          </CountryPlaceholder>
          <Input
            inputColor={inputColor}
            inputPadding={
              inputPadding ||
              (variant === "v3" ? "10px 38px 10px 116px" : "3px 16px 3px 100px")
            }
            type="text"
            placeholder={placeholder}
            pattern="[0-9]*"
            inputMode="decimal"
            name="mobile"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            autoComplete="off"
            ref={reference}
            required={required}
            variant={variant}
            inputBorderRadius={inputBorderRadius}
            focusable={focusable}
            error={error && variant === "v3" ? error : ""}
            {...rest}
          />
        </InputWrap>
        {error && variant !== "v3" && (
          <InputError hasBottomBorder error={error} />
        )}
      </InputGroup>
    </FlexDiv>
  );
}

PhoneInput.propTypes = {
  showLabels: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  focusable: PropTypes.bool,
  variant: PropTypes.string,
  error: PropTypes.string,
  mb: PropTypes.string,
  reference: propTypeRef,
  inputBorderRadius: PropTypes.string,
};

PhoneInput.defaultProps = {
  showLabels: false,
  placeholder: "",
  value: "",
  onChange: noop,
  onBlur: noop,
  required: false,
  variant: "v2",
  error: "",
  mb: "",
  inputBorderRadius: "10px",
  focusable: false,
  reference: noop,
};

export default PhoneInput;
