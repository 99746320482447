import styled from "styled-components";

import { Box, FlexSection, FlexDiv, FlexContainer } from "@/components/Atoms/Box";
import Image from "@/components/Atoms/Image";
import SVGSprite from "@/components/Atoms/SVGSprite";

import Typography from "@/foundation/Typography/V2";

export const Wrapper = styled(FlexSection)`
  padding: 40px 0;
  background-color: ${({ theme }) => theme.colorsV2.gray.shade_50};
  @media only screen and (max-width: 1023px) {
    padding: 24px 0;
  }
`;
export const Container = styled(FlexContainer)`
  flex-direction: column;
  padding: 0 40px;
  @media only screen and (max-width: 1023px) {
    padding: 0;
  }
`;

export const SliderWrapper = styled(Box)`
  margin: 0 -8px;
  .slick-prev,
  .slick-next {
    top: -28px;
    display: block !important;
    height: 32px;
    width: 32px;
    &:before {
      height: 100%;
      width: 100%;
      content: "";
      background: url(${({ arrowImage }) => `/images/${arrowImage}`}) center
        no-repeat;
      background-size: 100%;
      display: block;
      opacity: 1;
    }
    &.slick-disabled {
      &:before {
        opacity: 0.25;
      }
    }
    @media only screen and (max-width: 1023px) {
      height: 28px;
      width: 28px;
    }
    @media only screen and (max-width: 639px) {
      top: -20px;
    }
    @media only screen and (max-width: 395px) {
      top: -32px;
    }
  }
  .slick-next {
    right: 10px;
  }
  .slick-prev {
    left: unset;
    right: 48px;
    &:before {
      transform: rotateY(180deg);
    }
  }
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: auto !important;
  }
  .slick-dots {
    bottom: 0;
    li {
      margin: 0 4px;
      height: unset;
      width: unset;
      button {
        padding: 0;
        height: 8px;
        width: 8px;
        position: relative;
        &:before {
          content: "";
          transition: all 0.3s ease-in-out;
          background-color: ${({ theme }) => theme.colors.gray_shade_110};
          border-radius: 50%;
          height: 100%;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 1;
        }
      }
      &.slick-active {
        button {
          &:before {
            background-color: ${({ dotsColor, theme }) =>
              dotsColor || theme.colorsV2.shyft.purple_text};
          }
        }
      }
    }
  }
  .slick-list {
    padding: 40px 0 64px;
    @media only screen and (max-width: 1023px) {
      padding: 24px 0 48px;
    }
  }
  .slick-slide {
    & > div {
      display: flex;
      height: 100%;
      margin: 0 8px;
      @media only screen and (max-width: 1023px) {
        margin: 0;
        padding: 0 4px;
      }
    }
    &:last-child {
      @media only screen and (max-width: 1023px) {
        > div {
          padding-right: 8px;
        }
      }
    }
    &:first-child {
      @media only screen and (max-width: 1023px) {
        > div {
          padding-left: 8px;
        }
      }
    }
  }
  @media only screen and (max-width: 1023px) {
    margin: 0;
  }
`;
export const CardWrapper = styled(FlexDiv)`
  padding: 0;
  position: relative;
  border-radius: 8px;
  display: flex !important;
  flex-direction: column;
  min-height: 400px;
  outline: 0;
  overflow: hidden;
  box-shadow: 0px 0px 4px
      rgba(${({ theme }) => theme.colors.shadows.black}, 0.04),
    0px 4px 8px rgba(${({ theme }) => theme.colors.shadows.black}, 0.06);
  @media only screen and (max-width: 1023px) {
    min-height: unset;
  }
`;
export const ImageComponent = styled(Image)``;
export const ImageHolder = styled(FlexDiv)`
  height: 192px;
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colorsV2.gray.shade_200};
  ${ImageComponent} {
    width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media only screen and (max-width: 1023px) {
    height: 144px;
  }
`;
export const BodyComponent = styled(FlexDiv)`
  flex-direction: column;
  padding: 24px;
  flex: 1;
  @media only screen and (max-width: 767px) {
    padding: 16px 8px;
  }
`;
export const Title = styled(Typography)`
  padding: 0 8px;
  color: ${({ theme }) => theme.colorsV2.primary.shade_800};
  margin-bottom: 8px;
`;
export const TagsWrapper = styled(FlexDiv)`
  flex-wrap: wrap;
`;
export const TagBox = styled(Typography)`
  padding: 4px 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colorsV2.primary.shade_50};
  border-radius: 32px;
  color: ${({ theme }) => theme.colorsV2.gray.shade_700};
`;
export const About = styled(Typography)`
  padding: 8px;
  color: ${({ theme }) => theme.colors.gray_90};
`;
export const CTABox = styled(FlexDiv)`
  margin-top: 8px;
  flex: 1;
  align-items: flex-end;
`;
export const CTABtn = styled(Typography)`
  color: ${({ theme }) => theme.colorsV2.shyft.purple_text};
  display: flex;
  align-items: center;
`;
export const CtaLink = styled(Typography)`
  border: 1px solid ${({ theme }) => theme.colorsV2.primary.shade_200};
  background-color: ${({ theme }) => theme.colorsV2.primary.shade_50};
  padding: 6px 16px;
  border-radius: 8px;
`;
export const Icon = styled(SVGSprite)`
  height: 12px;
  margin-left: 8px;
  width: 12px;
  fill: ${({ theme }) => theme.colorsV2.shyft.purple_text};
`;
