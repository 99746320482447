import React from "react";
import PropTypes from "prop-types";

import { FlexDiv } from "@/components/Atoms/Box";
import Select from "@/components/Atoms/Inputs/Select";
import InputError from "../InputError";

import { noop } from "@/utils";

import { SelectGroup, GoalLabel } from "./style";

function Dropdown({
  options,
  placeholder,
  name,
  value,
  onChange,
  error,
  menuIsOpen,
  dropdownPosition,
  dropDownHeight,
  containerPadding,
  borderRadius,
  variant,
  isSearchable,
  goalLabel,
  isDisabled,
  dropDownWidth,
  minHeight,
  inputMarginBottom,
  onBlur,
  ...rest
}) {
  return (
    <FlexDiv flexDirection={"column"} {...rest}>
      {goalLabel && <GoalLabel variant="label3">{goalLabel}</GoalLabel>}
      <SelectGroup inputMarginBottom={inputMarginBottom}>
        <Select
          options={options}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          menuIsOpen={menuIsOpen}
          containerPadding={containerPadding}
          dropDownHeight={dropDownHeight}
          dropdownPos={dropdownPosition}
          borderRadius={borderRadius}
          variant={variant}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          minHeight={minHeight}
          onBlur={onBlur}
        />
        {error && <InputError error={error} />}
      </SelectGroup>
    </FlexDiv>
  );
}

Dropdown.propTypes = {
  options: PropTypes.instanceOf(Array),
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  error: PropTypes.string,
  menuIsOpen: PropTypes.string,
  dropdownPosition: PropTypes.string,
  dropDownHeight: PropTypes.string,
  containerPadding: PropTypes.string,
  borderRadius: PropTypes.string,
  variant: PropTypes.string,
  dropDownWidth: PropTypes.string,
  isSearchable: PropTypes.bool,
  showGoalLabel: PropTypes.bool,
};

Dropdown.defaultProps = {
  options: [],
  placeholder: "",
  name: "",
  onChange: noop,
  error: "",
  dropdownPosition: "bottom",
  dropDownHeight: "150px",
  containerPadding: "2px 16px",
  borderRadius: "10px",
  variant: "v2",
  isSearchable: false,
  showGoalLabel: false,
  dropDownWidth: "442px",
};

export default Dropdown;
